import axios from 'axios';
import { alertService } from '../services/alert.service';
import { observer } from 'mobx-react-lite';
import { humanize } from '../utils/stringUtils';

class TetherApi {
	constructor() {
		this.httpClient = axios.create({
			baseURL: process.env.REACT_APP_API_BASE,
		});

		this.httpClient.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				// Unauthorized / invalid authentication
				if (error?.response?.status === 401 || error?.response?.status === 403) {
					alertService.error('Action could not be completed.');

					//return logout();
				}
				// Validation error
				else if (error?.response?.status === 422) {
					let message = '';

					const errors = Object.entries(error?.response?.data?.errors);

					for (const [field, validationMessage] of errors) {
						message += `${humanize(field)} ${validationMessage}\n`;
					}

					if (message === '') {
						message = 'Record could not be saved.';
					}

					alertService.warn(message);
				} else {
					alertService.error('An error occurred.');
				}
			},
		);
	}

	defaultHeaders = () => {
		return { Authorization: `Bearer ${localStorage.getItem('jwt')}` };
	};

	fileUploadHeaders = () => {
		return {
			Authorization: `Bearer ${localStorage.getItem('jwt')}`,
			'Content-Type': 'multipart/form-data',
			'Content-Disposition': 'form-data',
		};
	};

	processPath = (path) => {
		const orgId = localStorage.getItem('orgId');

		if (orgId === null || orgId === '' || orgId === undefined || orgId === 'undefined') {
			return path;
		} else if (path.includes('?')) {
			return `${path}&orgId=${orgId}`;
		} else {
			return `${path}?orgId=${orgId}`;
		}
	};

	// Lookup / utility
	me = async () => {
		const result = await this.httpClient.get('/users/me', {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPathwayTypes = async () => {
		const result = await this.httpClient.get('/pathway_types', {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPracticeTypes = async () => {
		const result = await this.httpClient.get('/practice_types', {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPromptTypes = async () => {
		const result = await this.httpClient.get('/prompt_types', {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getStepTypes = async (type = '') => {
		const result = await this.httpClient.get(`/step_types?type=${type}`, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getTimezones = async () => {
		const result = await this.httpClient.get('/timezones', {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getDashboard = async () => {
		const result = await this.httpClient.get(this.processPath('/dashboard'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createPreSignedVimeoUpload = async (data) => {
		const result = await this.httpClient.post(this.processPath('/pre_signed_vimeo_uploads'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Authors
	getAuthors = async () => {
		const result = await this.httpClient.get(this.processPath('/authors'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getAuthor = async (authorId) => {
		const result = await this.httpClient.get(this.processPath(`/authors/${authorId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createAuthor = async (data) => {
		const result = await this.httpClient.post(this.processPath('/authors'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateAuthor = async (authorId, data) => {
		const result = await this.httpClient.put(this.processPath(`/authors/${authorId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deleteAuthor = async (authorId) => {
		const result = await this.httpClient.delete(this.processPath(`/authors/${authorId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Podcasts
	getPodcastCategories = async () => {
		const result = await this.httpClient.get(this.processPath('/podcast_categories'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPodcasts = async () => {
		const result = await this.httpClient.get(this.processPath('/podcasts'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPodcast = async (podcastId) => {
		const result = await this.httpClient.get(this.processPath(`/podcasts/${podcastId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createPodcast = async (data) => {
		const result = await this.httpClient.post(this.processPath('/podcasts'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updatePodcast = async (podcastId) => {
		const result = await this.httpClient.put(this.processPath(`/podcasts/${podcastId}`), {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deletePodcast = async (podcastId) => {
		const result = await this.httpClient.delete(this.processPath(`/podcasts/${podcastId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Podcast Epidosdes
	getPodcastEpisodes = async (podcastId, page, perPage) => {
		const result = await this.httpClient.get(
			this.processPath(`/podcasts/${podcastId}/podcast_episodes?page=${page}&perPage=${perPage}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getPodcastEpisode = async (podcastId, podcastEpisodeId) => {
		const result = await this.httpClient.get(
			this.processPath(`/podcasts/${podcastId}/podcast_episodes/${podcastEpisodeId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createPodcastEpisode = async (podcastId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/podcasts/${podcastId}/podcast_episodes`),
			data, // Ensure data is passed here
			{
				headers: this.fileUploadHeaders(),
			},
		);

		return result && result.data;
	};

	updatePodcastEpisode = async (podcastId, podcastEpisodeId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/podcasts/${podcastId}/podcast_episodes/${podcastEpisodeId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deletePodcastEpisode = async (podcastId, podcastEpisodeId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/pathways/${podcastId}/pathway_sections/${podcastEpisodeId}`),
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	// Access Codes
	getAccessCodes = async (pathwayId) => {
		const result = await this.httpClient.get(this.processPath(`pathways/${pathwayId}/access_codes`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createAccessCode = async (pathwayId, data) => {
		const result = await this.httpClient.post(this.processPath(`pathways/${pathwayId}/access_codes`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deleteAccessCode = async (pathwayId, accessCodeId) => {
		const result = await this.httpClient.delete(
			this.processPath(`pathways/${pathwayId}/access_codes/${accessCodeId}`),
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	getAccessCode = async (pathwayId, accessCodeId) => {
		const result = await this.httpClient.get(
			this.processPath(`pathways/${pathwayId}/access_codes/${accessCodeId}`),
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	getAccessCodeRedemptions = async (pathwayId, accessCodeId) => {
		const result = await this.httpClient.get(
			this.processPath(`pathways/${pathwayId}/access_codes/${accessCodeId}/redemptions`),
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	// Courses
	getCourses = async (page, perPage, opts = {}, includeGlobal = false) => {
		let path = `/courses?page=${page}&perPage=${perPage}&includeGlobal=${includeGlobal}`;

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });

		return result && result.data;
	};

	getCourse = async (courseId) => {
		const result = await this.httpClient.get(this.processPath(`/courses/${courseId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getCourseTypes = async () => {
		const result = await this.httpClient.get('/course_types', {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createCourse = async (data) => {
		const result = await this.httpClient.post(this.processPath('/courses'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateCourse = async (courseId, data) => {
		const result = await this.httpClient.put(this.processPath(`/courses/${courseId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deleteCourse = async (courseId) => {
		const result = await this.httpClient.delete(this.processPath(`/courses/${courseId}`), {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	duplicateCourse = async (courseId, data) => {
		const result = await this.httpClient.post(this.processPath(`/courses/${courseId}/duplicate`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	//Course Sections
	getCourseSections = async (courseId, page, perPage) => {
		const result = await this.httpClient.get(
			this.processPath(`/courses/${courseId}/course_sections?page=${page}&perPage=${perPage}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getCourseSection = async (courseId, courseSectionId) => {
		const result = await this.httpClient.get(
			this.processPath(`/courses/${courseId}/course_sections/${courseSectionId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createCourseSection = async (courseId, data) => {
		const result = await this.httpClient.post(this.processPath(`/courses/${courseId}/course_sections`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateCourseSection = async (courseId, courseSectionId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/courses/${courseId}/course_sections/${courseSectionId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deleteCourseSection = async (courseId, courseSectionId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/courses/${courseId}/course_sections/${courseSectionId}`),
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	duplicateCourseSection = async (courseId, courseSectionId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/courses/${courseId}/course_sections/${courseSectionId}/duplicate`),
			data,
			{
				headers: this.fileUploadHeaders(),
			},
		);

		return result && result.data;
	};

	// CourseSectionSteps
	getCourseSectionSteps = async (courseSectionId) => {
		const result = await this.httpClient.get(
			this.processPath(`/course_sections/${courseSectionId}/course_section_steps`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getCourseSectionStep = async (courseSectionId, courseSectionStepId) => {
		const result = await this.httpClient.get(
			this.processPath(`/course_sections/${courseSectionId}/course_section_steps/${courseSectionStepId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createCourseSectionStep = async (courseSectionId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/course_sections/${courseSectionId}/course_section_steps`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	updateCourseSectionStep = async (courseSectionId, courseSectionStepId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/course_sections/${courseSectionId}/course_section_steps/${courseSectionStepId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deleteCourseSectionStep = async (courseSectionId, courseSectionStepId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/course_sections/${courseSectionId}/course_section_steps/${courseSectionStepId}`),
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	// Pathways
	getPathways = async (page, perPage, opts = {}, includeGlobal = false) => {
		let path = `/pathways?page=${page}&perPage=${perPage}&includeGlobal=${includeGlobal}`;

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });

		return result && result.data;
	};

	getPathway = async (pathwayId) => {
		const result = await this.httpClient.get(this.processPath(`/pathways/${pathwayId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createPathway = async (data) => {
		const result = await this.httpClient.post(this.processPath('/pathways'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updatePathway = async (pathwayId, data) => {
		const result = await this.httpClient.put(this.processPath(`/pathways/${pathwayId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deletePathway = async (pathwayId) => {
		const result = await this.httpClient.delete(this.processPath(`/pathways/${pathwayId}`), {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	duplicatePathway = async (pathwayId, data) => {
		const result = await this.httpClient.post(this.processPath(`/pathways/${pathwayId}/duplicate`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	// PathwaySections
	getPathwaySections = async (pathwayId, page, perPage) => {
		const result = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/pathway_sections?page=${page}&perPage=${perPage}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getPathwaySection = async (pathwayId, pathwaySectionId) => {
		const result = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/pathway_sections/${pathwaySectionId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createPathwaySection = async (pathwayId, data) => {
		const result = await this.httpClient.post(this.processPath(`/pathways/${pathwayId}/pathway_sections`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updatePathwaySection = async (pathwayId, pathwaySectionId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/pathways/${pathwayId}/pathway_sections/${pathwaySectionId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deletePathwaySection = async (pathwayId, pathwaySectionId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/pathways/${pathwayId}/pathway_sections/${pathwaySectionId}`),
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	duplicatePathwaySection = async (pathwayId, pathwaySectionId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/pathways/${pathwayId}/pathway_sections/${pathwaySectionId}/duplicate`),
			data,
			{
				headers: this.fileUploadHeaders(),
			},
		);

		return result && result.data;
	};

	// PathwaySectionSteps
	getPathwaySectionSteps = async (pathwaySectionId) => {
		const result = await this.httpClient.get(
			this.processPath(`/pathway_sections/${pathwaySectionId}/pathway_section_steps`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getPathwaySectionStep = async (pathwaySectionId, pathwaySectionStepId) => {
		const result = await this.httpClient.get(
			this.processPath(`/pathway_sections/${pathwaySectionId}/pathway_section_steps/${pathwaySectionStepId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createPathwaySectionStep = async (pathwaySectionId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/pathway_sections/${pathwaySectionId}/pathway_section_steps`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	updatePathwaySectionStep = async (pathwaySectionId, pathwaySectionStepId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/pathway_sections/${pathwaySectionId}/pathway_section_steps/${pathwaySectionStepId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deletePathwaySectionStep = async (pathwaySectionId, pathwaySectionStepId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/pathway_sections/${pathwaySectionId}/pathway_section_steps/${pathwaySectionStepId}`),
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	// Practices
	getPractices = async (page, perPage, opts = {}, includeGlobal = false) => {
		let path = `/practices?page=${page}&perPage=${perPage}&includeGlobal=${includeGlobal}`;

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });

		return result && result.data;
	};

	getPractice = async (practiceId) => {
		const result = await this.httpClient.get(this.processPath(`/practices/${practiceId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createPractice = async (data) => {
		const result = await this.httpClient.post(this.processPath('/practices'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updatePractice = async (practiceId, data) => {
		const result = await this.httpClient.put(this.processPath(`/practices/${practiceId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deletePractice = async (practiceId) => {
		const result = await this.httpClient.delete(this.processPath(`/practices/${practiceId}`), {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	duplicatePractice = async (practiceId, data) => {
		const result = await this.httpClient.post(this.processPath(`/practices/${practiceId}/duplicate`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	// PracticeSteps
	getPracticeSteps = async (practiceId, page, perPage) => {
		const result = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/practice_steps?page=${page}&perPage=${perPage}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getPracticeStep = async (practiceId, practiceStepId) => {
		const result = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/practice_steps/${practiceStepId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createPracticeStep = async (practiceId, data) => {
		const result = await this.httpClient.post(this.processPath(`/practices/${practiceId}/practice_steps`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updatePracticeStep = async (practiceId, practiceStepId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/practices/${practiceId}/practice_steps/${practiceStepId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deletePracticeStep = async (practiceId, practiceStepId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/practices/${practiceId}/practice_steps/${practiceStepId}`),
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	// Prompts
	getPrompts = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/prompts?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPrompt = async (promptId) => {
		const result = await this.httpClient.get(this.processPath(`/prompts/${promptId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createPrompt = async (data) => {
		const result = await this.httpClient.post(this.processPath('/prompts'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updatePrompt = async (promptId, data) => {
		const result = await this.httpClient.put(this.processPath(`/prompts/${promptId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deletePrompt = async (promptId) => {
		const result = await this.httpClient.delete(this.processPath(`/prompts/${promptId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Orgs
	getOrgs = async () => {
		const result = await this.httpClient.get(this.processPath('/orgs'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getOrg = async (orgId) => {
		const result = await this.httpClient.get(this.processPath(`/orgs/${orgId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createOrg = async (data) => {
		const result = await this.httpClient.post(this.processPath('/orgs'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateOrg = async (orgId, data) => {
		const result = await this.httpClient.put(this.processPath(`/orgs/${orgId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	signUpOrg = async (data) => {
		const result = await this.httpClient.post(this.processPath('/orgs/sign_up'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	// Features
	getFeatures = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/features?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getFeature = async (featureId) => {
		const result = await this.httpClient.get(this.processPath(`/features/${featureId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createFeature = async (data) => {
		const result = await this.httpClient.post(this.processPath('/features'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateFeature = async (featureId, data) => {
		const result = await this.httpClient.put(this.processPath(`/features/${featureId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteFeature = async (featureId) => {
		const result = await this.httpClient.delete(this.processPath(`/features/${featureId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// CheckInPeriodImages
	getCheckInPeriodImages = async (page, perPage) => {
		const result = await this.httpClient.get(
			this.processPath(`/check_in_period_images?page=${page}&perPage=${perPage}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getCheckInPeriodImage = async (checkInPeriodImageId) => {
		const result = await this.httpClient.get(this.processPath(`/check_in_period_images/${checkInPeriodImageId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createCheckInPeriodImage = async (data) => {
		const result = await this.httpClient.post(this.processPath('/check_in_period_images'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateCheckInPeriodImage = async (checkInPeriodImageId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/check_in_period_images/${checkInPeriodImageId}`),
			data,
			{ headers: this.fileUploadHeaders() },
		);

		return result && result.data;
	};

	deleteCheckInPeriodImage = async (checkInPeriodImageId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/check_in_period_images/${checkInPeriodImageId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// Groups
	getGroups = async (page, perPage, opts = {}) => {
		let path = `/groups?page=${page}&perPage=${perPage}`;

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	searchGroups = async (query = '') => {
		let path = `/groups/search?query=${query}`;

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getGroup = async (groupId) => {
		const result = await this.httpClient.get(this.processPath(`/groups/${groupId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createGroup = async (data) => {
		const result = await this.httpClient.post(this.processPath('/groups'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deleteGroup = async (groupId) => {
		const result = await this.httpClient.delete(this.processPath(`/groups/${groupId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateGroup = async (groupId, data) => {
		const result = await this.httpClient.put(this.processPath(`/groups/${groupId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateGroupOrg = async (groupId, orgId) => {
		const result = await this.httpClient.put(
			this.processPath(`/groups/${groupId}/update_org`),
			{ updateOrgId: orgId },
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	addUserToGroup = async (groupId, userId) => {
		const result = await this.httpClient.put(
			this.processPath(`/groups/${groupId}/add_user`),
			{ user_id: userId },
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	updateUserRoleInGroup = async (groupId, userId, isLeader) => {
		const result = await this.httpClient.put(
			this.processPath(`/groups/${groupId}/add_user`),
			{ user_id: userId, is_leader: isLeader },
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	removeUserFromGroup = async (groupId, userId) => {
		const result = await this.httpClient.put(
			this.processPath(`/groups/${groupId}/remove_user`),
			{ user_id: userId },
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// Goals
	getGoals = async () => {
		const result = await this.httpClient.get(this.processPath('/goals'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getGoal = async (goalId) => {
		const result = await this.httpClient.get(this.processPath(`/goals/${goalId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createGoal = async (data) => {
		const result = await this.httpClient.post(this.processPath('/goals'), data, { headers: this.defaultHeaders() });

		return result && result.data;
	};

	updateGoal = async (goalId, data) => {
		const result = await this.httpClient.put(this.processPath(`/goals/${goalId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteGoal = async (goalId) => {
		const result = await this.httpClient.delete(this.processPath(`/goals/${goalId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// LeaderResourceFolders
	getLeaderResourceFolder = async (leaderResourceFolderId) => {
		const result = await this.httpClient.get(
			this.processPath(`/leader_resource_folders/${leaderResourceFolderId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createLeaderResourceFolder = async (data) => {
		const result = await this.httpClient.post(this.processPath('/leader_resource_folders'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateLeaderResourceFolder = async (leaderResourceFolderId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/leader_resource_folders/${leaderResourceFolderId}`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	deleteLeaderResourceFolder = async (leaderResourceFolderId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/leader_resource_folders/${leaderResourceFolderId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// LeaderResources
	getLeaderResources = async () => {
		const result = await this.httpClient.get(this.processPath('/leader_resources'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getLeaderResource = async (leaderResourceId) => {
		const result = await this.httpClient.get(this.processPath(`/leader_resources/${leaderResourceId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createLeaderResource = async (data) => {
		const result = await this.httpClient.post(this.processPath('/leader_resources'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateLeaderResource = async (leaderResourceId, data) => {
		const result = await this.httpClient.put(this.processPath(`/leader_resources/${leaderResourceId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteLeaderResource = async (leaderResourceId) => {
		const result = await this.httpClient.delete(this.processPath(`/leader_resources/${leaderResourceId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Invitations
	getInvitations = async (page, perPage, opts = {}) => {
		let path = `/invitations?page=${page}&perPage=${perPage}`;

		if (opts.groupId) {
			path += `&groupId=${opts.groupId}`;
		}
		if (opts.orgId) {
			path += `&orgId=${opts.orgId}`;
		}

		if (opts.status) {
			path += `&status=${opts.status}`;
		}

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	searchInvitations = async (query = '', opts = {}) => {
		let path = `/invitations/search?query=${query}`;

		if (opts.status) {
			path += `&status=${opts.status}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getInvitation = async (invitationId) => {
		const result = await this.httpClient.get(this.processPath(`/invitations/${invitationId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createInvitation = async (data) => {
		const result = await this.httpClient.post(this.processPath('/invitations'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteInvitation = async (invitationId) => {
		const result = await this.httpClient.delete(this.processPath(`/invitations/${invitationId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	resendInvitation = async (invitationId) => {
		const result = await this.httpClient.put(
			this.processPath(`/invitations/${invitationId}/resend`),
			{},
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	// Assessments
	getAssessments = async (page, perPage, opts = {}) => {
		let path = `/assessments?page=${page}&perPage=${perPage}`;

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getAssessment = async (assessmentId) => {
		const result = await this.httpClient.get(this.processPath(`/assessments/${assessmentId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createAssessment = async (data) => {
		const result = await this.httpClient.post(this.processPath('/assessments'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateAssessment = async (assessmentId, data) => {
		const result = await this.httpClient.put(this.processPath(`/assessments/${assessmentId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteAssessment = async (assessmentId) => {
		const result = await this.httpClient.delete(this.processPath(`/assessments/${assessmentId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// AssessmentQuestions
	getAssessmentQuestions = async (assessmentId) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessments/${assessmentId}/assessment_questions`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getAssessmentQuestion = async (assessmentId, assessmentQuestionId) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createAssessmentQuestion = async (assessmentId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/assessments/${assessmentId}/assessment_questions`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	updateAssessmentQuestion = async (assessmentId, assessmentQuestionId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	deleteAssessmentQuestion = async (assessmentId, assessmentQuestionId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/assessments/${assessmentId}/assessment_questions/${assessmentQuestionId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// AssessmentQuestionAnswers
	getAssessmentQuestionAnswers = async (assessmentQuestionId) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessment_questions/${assessmentQuestionId}/assessment_question_answers`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getAssessmentQuestionAnswer = async (assessmentQuestionId, assessmentQuestionAnswerId) => {
		const result = await this.httpClient.get(
			this.processPath(
				`/assessment_questions/${assessmentQuestionId}/assessment_question_answers/${assessmentQuestionAnswerId}`,
			),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createAssessmentQuestionAnswer = async (assessmentQuestionId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/assessment_questions/${assessmentQuestionId}/assessment_question_answers`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	updateAssessmentQuestionAnswer = async (assessmentQuestionId, assessmentQuestionAnswerId, data) => {
		const result = await this.httpClient.put(
			this.processPath(
				`/assessment_questions/${assessmentQuestionId}/assessment_question_answers/${assessmentQuestionAnswerId}`,
			),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	deleteAssessmentQuestionAnswer = async (assessmentQuestionId, assessmentQuestionAnswerId) => {
		const result = await this.httpClient.delete(
			this.processPath(
				`/assessment_questions/${assessmentQuestionId}/assessment_question_answers/${assessmentQuestionAnswerId}`,
			),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// AssessmentActionLinks
	getAssessmentActionLinks = async (assessmentId) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessments/${assessmentId}/assessment_action_links`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getAssessmentActionLink = async (assessmentId, assessmentActionLinkId) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessments/${assessmentId}/assessment_action_links/${assessmentActionLinkId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createAssessmentActionLink = async (assessmentId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/assessments/${assessmentId}/assessment_action_links`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	updateAssessmentActionLink = async (assessmentId, assessmentActionLinkId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/assessments/${assessmentId}/assessment_action_links/${assessmentActionLinkId}`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	deleteAssessmentActionLink = async (assessmentId, assessmentActionLinkId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/assessments/${assessmentId}/assessment_action_links/${assessmentActionLinkId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// AssessmentPerios
	getAssessmentPeriods = async (assessmentId, page, perPage) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessments/${assessmentId}/assessment_periods?page=${page}&perPage=${perPage}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	getAssessmentPeriod = async (assessmentId, assessmentPeriodId) => {
		const result = await this.httpClient.get(
			this.processPath(`/assessments/${assessmentId}/assessment_periods/${assessmentPeriodId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// Channels
	getChannels = async () => {
		const result = await this.httpClient.get(this.processPath('/channels'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getChannel = async (channelId) => {
		const result = await this.httpClient.get(this.processPath(`/channels/${channelId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createChannel = async (data) => {
		const result = await this.httpClient.post(this.processPath('/channels'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateChannel = async (channelId, data) => {
		const result = await this.httpClient.put(this.processPath(`/channels/${channelId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteChannel = async (channelId) => {
		const result = await this.httpClient.delete(this.processPath(`/channels/${channelId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	addUserToChannel = async (channelId, userId) => {
		const result = await this.httpClient.put(
			this.processPath(`/channels/${channelId}/add_user`),
			{ user_id: userId },
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	removeUserFromChannel = async (channelId, userId) => {
		const result = await this.httpClient.put(
			this.processPath(`/channels/${channelId}/remove_user`),
			{ user_id: userId },
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// Challenges
	getChallenges = async () => {
		const result = await this.httpClient.get(this.processPath('/challenges'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getChallenge = async (challengeId) => {
		const result = await this.httpClient.get(this.processPath(`/challenges/${challengeId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createChallenge = async (data) => {
		const result = await this.httpClient.post(this.processPath('/challenges'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateChallenge = async (challengeId, data) => {
		const result = await this.httpClient.put(this.processPath(`/challenges/${challengeId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteChallenge = async (challengeId) => {
		const result = await this.httpClient.delete(this.processPath(`/challenges/${challengeId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Users
	getUsers = async (page, perPage, opts = {}) => {
		let path = `/users?page=${page}&perPage=${perPage}`;

		if (opts.channelId) {
			path += `&channelId=${opts.channelId}`;
		}

		if (opts.roles) {
			path += `&roles=${opts.roles}`;
		}

		if (opts.orgId) {
			path += `&orgId=${opts.orgId}`;
		}

		if (opts.groupId) {
			path += `&groupId=${opts.groupId}`;
		}

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	searchUsers = async (query = '', opts = {}) => {
		let path = `/users/search?query=${query}`;

		if (opts.channelId) {
			path += `&channelId=${opts.channelId}`;
		}

		if (opts.roles) {
			path += `&roles=${opts.roles}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getUser = async (userId) => {
		const result = await this.httpClient.get(this.processPath(`/users/${userId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateUser = async (userId, data) => {
		const result = await this.httpClient.put(this.processPath(`/users/${userId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createUser = async (data) => {
		const result = await this.httpClient.post(this.processPath('/users/sign_up'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteUser = async (userId) => {
		const result = await this.httpClient.delete(this.processPath(`/users/${userId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// CustomFields
	getCustomFields = async () => {
		const result = await this.httpClient.get(this.processPath('/custom_fields'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getCustomField = async (customFieldId) => {
		const result = await this.httpClient.get(this.processPath(`/custom_fields/${customFieldId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createCustomField = async (data) => {
		const result = await this.httpClient.post(this.processPath('/custom_fields'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateCustomField = async (customFieldId, data) => {
		const result = await this.httpClient.put(this.processPath(`/custom_fields/${customFieldId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteCustomField = async (customFieldId) => {
		const result = await this.httpClient.delete(this.processPath(`/custom_fields/${customFieldId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Collections
	getCollections = async () => {
		const result = await this.httpClient.get(this.processPath('/collections'), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getCollection = async (collectionId) => {
		const result = await this.httpClient.get(this.processPath(`/collections/${collectionId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createCollection = async (data) => {
		const result = await this.httpClient.post(this.processPath('/collections'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateCollection = async (collectionId, data) => {
		const result = await this.httpClient.put(this.processPath(`/collections/${collectionId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteCollection = async (collectionId) => {
		const result = await this.httpClient.delete(this.processPath(`/collections/${collectionId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// CollectionItem
	getCollectionItems = async (collectionId) => {
		const result = await this.httpClient.get(this.processPath(`/collections/${collectionId}/collection_items`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getCollectionItem = async (collectionId, collectionItemId) => {
		const result = await this.httpClient.get(
			this.processPath(`/collections/${collectionId}/collection_items/${collectionItemId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	createCollectionItem = async (collectionId, data) => {
		const result = await this.httpClient.post(
			this.processPath(`/collections/${collectionId}/collection_items`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	updateCollectionItem = async (collectionId, collectionItemId, data) => {
		const result = await this.httpClient.put(
			this.processPath(`/collections/${collectionId}/collection_items/${collectionItemId}`),
			data,
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	deleteCollectionItem = async (collectionId, collectionItemId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/collections/${collectionId}/collection_items/${collectionItemId}`),
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// PaymentMethods
	getPaymentMethods = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/payment_methods?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getPaymentMethod = async (paymentMethodId) => {
		const result = await this.httpClient.get(this.processPath(`/payment_methods/${paymentMethodId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Subscriptions
	getSubscriptions = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/subscriptions?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getSubscription = async (subscriptionId) => {
		const result = await this.httpClient.get(this.processPath(`/subscriptions/${subscriptionId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Invoices
	getInvoices = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/invoices?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getInvoice = async (invoiceId) => {
		const result = await this.httpClient.get(this.processPath(`/invoices/${invoiceId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Stripe
	createCheckoutSession = async () => {
		const result = await this.httpClient.post(
			this.processPath('/stripe/checkout_sessions'),
			{},
			{ headers: this.defaultHeaders() },
		);

		return result && result.data;
	};

	// Metrics
	newUserCountMetric = async (timeframe) => {
		let path = '/metrics/org_user_added/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	activeUserCountMetric = async (timeframe) => {
		let path = '/metrics/org_user_active/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	totalUsersMetric = async (userType) => {
		let path = '/metrics/org_user_added/count';
		if (userType) {
			path = `${path}?filterAttribute=${userType}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	totalUsersMetricToDate = async (maxDate) => {
		let path = `/metrics/org_user_added/count?maxRecordedAt=${maxDate}`;
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	moodsCompletedCountMetric = async (timeframe) => {
		let path = '/metrics/org_mood_created/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	challengesJoinedCountMetric = async (timeframe) => {
		let path = '/metrics/org_challenge_joined_user/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	pendingInvitationsMetric = async (timeframe, filterAttribute) => {
		let path = '/metrics/org_invitation_pending/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		if (filterAttribute) {
			let queryStringModifier = '?';
			if (path.includes('?')) {
				queryStringModifier = '&';
			}
			path = `${path}${queryStringModifier}filterAttribute=${filterAttribute}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	newGroupsMetric = async (timeframe) => {
		let path = '/metrics/org_group_created/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	activeGroupsMetric = async (timeframe) => {
		let path = '/metrics/org_group_active/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	totalPracticesMetric = async () => {
		let path = '/metrics/org_practice_created/count';
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	completedPracticesMetric = async (timeframe) => {
		let path = '/metrics/org_practice_completed/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	startedPracticesMetric = async (timeframe) => {
		let path = '/metrics/org_practice_started/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	completedHabitsMetric = async (timeframe) => {
		let path = '/metrics/org_habit_completed/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	savedPracticesMetric = async (timeframe) => {
		let path = '/metrics/org_practice_saved/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	totalPathwaysMetric = async () => {
		let path = '/metrics/org_pathway_created/count';
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	completedGroupPathwaysMetric = async (timeframe) => {
		let path = '/metrics/org_pathway_completed_group/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	completedIndividualPathwaysMetric = async (timeframe) => {
		let path = '/metrics/org_pathway_completed_user/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	subscribedGroupPathwaysMetric = async (timeframe) => {
		let path = '/metrics/org_pathway_started_group/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	subscribedIndividualPathwaysMetric = async (timeframe) => {
		let path = '/metrics/org_pathway_started_user/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	startedPathwaySectionsMetric = async (timeframe) => {
		let path = '/metrics/org_pathway_section_started_user/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	completedPathwaySectionsMetric = async (timeframe) => {
		let path = '/metrics/org_pathway_section_completed_user/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	postsCreatedMetric = async (timeframe, isPrayerRequest) => {
		let path = '/metrics/org_post_created/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		if (isPrayerRequest) {
			let queryStringModifier = '?';
			if (path.includes('?')) {
				queryStringModifier = '&';
			}
			path = `${path}${queryStringModifier}filterAttribute=prayer_request`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	activeAssessmentsMetric = async (timeframe) => {
		let path = '/metrics/org_assessment_active/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	inactiveAssessmentsMetric = async (timeframe) => {
		let path = '/metrics/org_assessment_inactive/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	completedAssessmentsMetric = async (timeframe) => {
		let path = '/metrics/org_assessment_completed/count';
		if (timeframe) {
			path = `${path}?minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data && result.data.data && result.data.data[0] ? result.data.data[0].count : 0;
	};

	groupedMoodsCreatedMetric = async (timeframe, groupedBy) => {
		let path = `/metrics/org_mood_created/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedTotalPostsMetric = async (timeframe, groupedBy) => {
		let path = `/metrics/org_post_created/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedGuideSectionsStarted = async (timeframe, groupedBy) => {
		let path = `/metrics/org_pathway_section_started_user/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedGuideSectionsCompleted = async (timeframe, groupedBy) => {
		let path = `/metrics/org_pathway_section_completed_user/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedAssessmentsCompleted = async (timeframe, groupedBy) => {
		let path = `/metrics/org_assessment_completed/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedPrayerRequests = async (timeframe, groupedBy) => {
		let path = `/metrics/org_post_created/grouped_count?groupBy=${groupedBy}&filterAttribute=prayer_request`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedPracticesStarted = async (timeframe, groupedBy) => {
		let path = `/metrics/org_practice_started/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedPracticesCompleted = async (timeframe, groupedBy) => {
		let path = `/metrics/org_practice_completed/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedHabitsCompleted = async (timeframe, groupedBy) => {
		let path = `/metrics/org_habit_completed/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	groupedChallengesJoined = async (timeframe, groupedBy) => {
		let path = `/metrics/org_challenge_joined_user/grouped_count?groupBy=${groupedBy}`;
		if (timeframe) {
			path = `${path}&minRecordedAt=${timeframe}`;
		}
		const result = await this.httpClient.get(this.processPath(path), { headers: this.defaultHeaders() });
		return result && result.data ? result.data.data : [];
	};

	completePlanningCenterOAuth = async (code) => {
		const result = await this.httpClient.post(
			this.processPath('/oauth/planning_center'),
			{ code: code },
			{
				headers: this.defaultHeaders(),
			},
		);
		return result && result.data;
	};

	// OrgHabits
	getOrgHabits = async (page, perPage, opts = {}) => {
		let path = `/org_habits?page=${page}&perPage=${perPage}`;

		if (opts.sortBy) {
			path += `&sortBy=${opts.sortBy}`;
		}

		if (opts.sortOrder) {
			path += `&sortOrder=${opts.sortOrder}`;
		}

		const result = await this.httpClient.get(this.processPath(path), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getOrgHabit = async (orgHabitId) => {
		const result = await this.httpClient.get(this.processPath(`/org_habits/${orgHabitId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createOrgHabit = async (data) => {
		const result = await this.httpClient.post(this.processPath('/org_habits'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateOrgHabit = async (orgHabitId, data) => {
		const result = await this.httpClient.put(this.processPath(`/org_habits/${orgHabitId}`), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteOrgHabit = async (orgHabitId) => {
		const result = await this.httpClient.delete(this.processPath(`/org_habits/${orgHabitId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// StockImages
	getStockImages = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/stock_images?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getStockImage = async (stockImageId) => {
		const result = await this.httpClient.get(this.processPath(`/stock_images/${stockImageId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createStockImage = async (data) => {
		const result = await this.httpClient.post(this.processPath('/stock_images'), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	updateStockImage = async (stockImageId, data) => {
		const result = await this.httpClient.put(this.processPath(`/stock_images/${stockImageId}`), data, {
			headers: this.fileUploadHeaders(),
		});

		return result && result.data;
	};

	deleteStockImage = async (stockImageId) => {
		const result = await this.httpClient.delete(this.processPath(`/stock_images/${stockImageId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Imports
	getImports = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/imports?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	getImport = async (importId) => {
		const result = await this.httpClient.get(this.processPath(`/imports/${importId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createImport = async (data) => {
		// Do not processPath here to ensure the selected orgId from super admin is respected
		const result = await this.httpClient.post('/imports', data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	updateImportProcessPreview = async (importId) => {
		const result = await this.httpClient.put(
			this.processPath(`/imports/${importId}/process_preview`),
			{},
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	updateImportProcessImport = async (importId) => {
		const result = await this.httpClient.put(
			this.processPath(`/imports/${importId}/process_import`),
			{},
			{
				headers: this.defaultHeaders(),
			},
		);

		return result && result.data;
	};

	deleteImport = async (importId) => {
		const result = await this.httpClient.delete(this.processPath(`/imports/${importId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Exports
	getExports = async (page, perPage) => {
		const result = await this.httpClient.get(this.processPath(`/exports?page=${page}&perPage=${perPage}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	createExport = async (data) => {
		const result = await this.httpClient.post(this.processPath('/exports'), data, {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	deleteExport = async (exportId) => {
		const result = await this.httpClient.delete(this.processPath(`/exports/${exportId}`), {
			headers: this.defaultHeaders(),
		});

		return result && result.data;
	};

	// Practice Stats
	getPracticeViewsCount = async (practiceId) => {
		let url = `/practices/${practiceId}/user_practices?countOnly=true`;
		const result = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return result && result.data && result.data.total;
	};

	getTotalPracticeCompletions = async (practiceId, time) => {
		const userResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?countOnly=true&minEndAt=${time}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		return userResult?.data?.total ?? 0;
	};

	getTimeBoundedPracticeCompletions = async (practiceId, time, endTime) => {
		let urlAdditions = `&minEndAt=${time}`;
		if (endTime) {
			urlAdditions = `${urlAdditions}&maxEndAt=${endTime}`;
		}
		const userResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?countOnly=true${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		return userResult?.data?.total ?? 0;
	};

	getTimeBoundedPracticeStarts = async (practiceId, time, endTime) => {
		let urlAdditions = `&minStartAt=${time}`;
		if (endTime) {
			urlAdditions = `${urlAdditions}&maxStartAt=${endTime}`;
		}
		const userResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?countOnly=true${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		return userResult?.data?.total ?? 0;
	};

	getPracticeSentiments = async (practiceId) => {
		let urlAdditions = '&countOnly=true';
		const greatResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?moodEmotion=great${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const goodResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?moodEmotion=good${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const okayResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?moodEmotion=okay${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const badResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?moodEmotion=bad${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const awfulResult = await this.httpClient.get(
			this.processPath(`/practices/${practiceId}/user_practices?moodEmotion=awful${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const greatCount = greatResult?.data?.total ?? 0;
		const goodCount = goodResult?.data?.total ?? 0;
		const okayCount = okayResult?.data?.total ?? 0;
		const badCount = badResult?.data?.total ?? 0;
		const awfulCount = awfulResult?.data?.total ?? 0;
		const totalCount = greatCount + goodCount + okayCount + badCount + awfulCount;
		return {
			great: {
				count: greatCount,
				percentage: (greatCount / totalCount) * 100,
			},
			good: {
				count: goodCount,
				percentage: (goodCount / totalCount) * 100,
			},
			okay: {
				count: okayCount,
				percentage: (okayCount / totalCount) * 100,
			},
			bad: {
				count: badCount,
				percentage: (badCount / totalCount) * 100,
			},
			awful: {
				count: awfulCount,
				percentage: (awfulCount / totalCount) * 100,
			},
		};
	};

	getPracticeAnonymousResponses = async (practiceId) => {
		let url = `/practices/${practiceId}/user_practices?hasMoodBody=true&perPage=3`;
		const responses = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return responses?.data;
	};

	getCourseViewsCount = async (courseId, courseSectionId) => {
		let url = `/courses/${courseId}/user_course_sections?countOnly=true`;
		if (courseSectionId) {
			url = `${url}&courseSectionId=${courseSectionId}`;
		}
		const result = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return result && result.data && result.data.total;
	};

	getTotalCourseSubscriptions = async (courseId) => {
		const userResult = await this.httpClient.get(
			this.processPath(`/courses/${courseId}/user_courses?countOnly=true`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const groupsResult = await this.httpClient.get(
			this.processPath(`/courses/${courseId}/group_courses?countOnly=true`),
			{
				headers: this.defaultHeaders(),
			},
		);
		if (userResult?.data && groupsResult?.data) {
			return userResult.data.total + groupsResult.data.total;
		}
		return 0;
	};

	getTotalCourseCompletions = async (courseId, time, courseSectionId) => {
		let url = `/courses/${courseId}`;
		let urlAdditions = '';
		if (courseSectionId) {
			url = `${url}/user_course_sections?countOnly=true`;
			urlAdditions = `&minEndAt=${time}&courseSectionId=${courseSectionId}`;
		} else {
			url = `${url}/user_courses?countOnly=true`;
			urlAdditions = `&minCompletedAt=${time}`;
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupCount = 0;
		if (!courseSectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/courses/${courseId}/group_courses?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupCount;
		}
		return 0;
	};

	getTotalCourseStarts = async (courseId, time, courseSectionId) => {
		let url = `/courses/${courseId}`;
		let urlAdditions = '';
		if (courseSectionId) {
			url = `${url}/user_course_sections?countOnly=true`;
			urlAdditions = `&minEndAt=${time}&courseSectionId=${courseSectionId}`;
		} else {
			url = `${url}/user_courses?countOnly=${true}`;
			urlAdditions = `&minCompletedAt=${time}`;
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupCount = 0;
		if (!courseSectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/courses/${courseId}/group_courses?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupCount;
		}
		return 0;
	};

	getTimeBoundedCourseCompletions = async (courseId, time, endTime, courseSectionId) => {
		let url = `/courses/${courseId}`;
		let urlAdditions = '';
		if (courseSectionId) {
			url = `${url}/user_course_sections?countOnly=true`;
			urlAdditions = `&minEndAt=${time}&courseSectionId=${courseSectionId}`;
			if (endTime) {
				urlAdditions = `${urlAdditions}&maxEndAt=${endTime}`;
			}
		} else {
			url = `${url}/user_courses?countOnly=true`;
			urlAdditions = `&minCompletedAt=${time}`;
			if (endTime) {
				urlAdditions = `${urlAdditions}&maxCompletedAt=${endTime}`;
			}
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupsCount = 0;
		if (!courseSectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/courses/${courseId}/group_courses?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupsCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupsCount;
		}
		return 0;
	};

	// Pathway Stats
	getUserPathwayStats = async (pathwayId, page, perPage) => {
		let url = `/user_pathway_stats/${pathwayId}?page=${page}&perPage=${perPage}`;
		const responses = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});

		return responses?.data;
	};

	getPathwayViewsCount = async (pathwayId, pathwaySectionId) => {
		let url = `/pathways/${pathwayId}/user_pathway_sections?countOnly=true`;
		if (pathwaySectionId) {
			url = `${url}&pathwaySectionId=${pathwaySectionId}`;
		}
		const result = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return result && result.data && result.data.total;
	};

	getTotalPathwaySubscriptions = async (pathwayId) => {
		const userResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/user_pathways?countOnly=true`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const groupsResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/group_pathways?countOnly=true`),
			{
				headers: this.defaultHeaders(),
			},
		);
		if (userResult?.data && groupsResult?.data) {
			return userResult.data.total + groupsResult.data.total;
		}
		return 0;
	};

	getTotalPathwayCompletions = async (pathwayId, time, pathwaySectionId) => {
		let url = `/pathways/${pathwayId}`;
		let urlAdditions = '';
		if (pathwaySectionId) {
			url = `${url}/user_pathway_sections?countOnly=true`;
			urlAdditions = `&minEndAt=${time}&pathwaySectionId=${pathwaySectionId}`;
		} else {
			url = `${url}/user_pathways?countOnly=true`;
			urlAdditions = `&minCompletedAt=${time}`;
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupCount = 0;
		if (!pathwaySectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/pathways/${pathwayId}/group_pathways?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupCount;
		}
		return 0;
	};

	getTotalPathwayStarts = async (pathwayId, time, pathwaySectionId) => {
		let url = `/pathways/${pathwayId}`;
		let urlAdditions = '';
		if (pathwaySectionId) {
			url = `${url}/user_pathway_sections?countOnly=true`;
			urlAdditions = `&minEndAt=${time}&pathwaySectionId=${pathwaySectionId}`;
		} else {
			url = `${url}/user_pathways?countOnly=${true}`;
			urlAdditions = `&minCompletedAt=${time}`;
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupCount = 0;
		if (!pathwaySectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/pathways/${pathwayId}/group_pathways?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupCount;
		}
		return 0;
	};

	getTimeBoundedPathwayCompletions = async (pathwayId, time, endTime, pathwaySectionId) => {
		let url = `/pathways/${pathwayId}`;
		let urlAdditions = '';
		if (pathwaySectionId) {
			url = `${url}/user_pathway_sections?countOnly=true`;
			urlAdditions = `&minEndAt=${time}&pathwaySectionId=${pathwaySectionId}`;
			if (endTime) {
				urlAdditions = `${urlAdditions}&maxEndAt=${endTime}`;
			}
		} else {
			url = `${url}/user_pathways?countOnly=true`;
			urlAdditions = `&minCompletedAt=${time}`;
			if (endTime) {
				urlAdditions = `${urlAdditions}&maxCompletedAt=${endTime}`;
			}
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupsCount = 0;
		if (!pathwaySectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/pathways/${pathwayId}/group_pathways?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupsCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupsCount;
		}
		return 0;
	};

	getTimeBoundedPathwayStarts = async (pathwayId, time, endTime, pathwaySectionId) => {
		let url = `/pathways/${pathwayId}`;
		let urlAdditions = '';
		if (pathwaySectionId) {
			url = `${url}/user_pathway_sections?countOnly=true`;
			urlAdditions = `&minStartAt=${time}&pathwaySectionId=${pathwaySectionId}`;
			if (endTime) {
				urlAdditions = `${urlAdditions}&maxStartAt=${endTime}`;
			}
		} else {
			url = `${url}/user_pathways?countOnly=true`;
			urlAdditions = `&minStartedAt=${time}`;
			if (endTime) {
				urlAdditions = `${urlAdditions}&maxStartedAt=${endTime}`;
			}
		}
		const userResult = await this.httpClient.get(this.processPath(`${url}${urlAdditions}`), {
			headers: this.defaultHeaders(),
		});
		let groupsCount = 0;
		if (!pathwaySectionId) {
			const groupsResult = await this.httpClient.get(
				this.processPath(`/pathways/${pathwayId}/group_pathways?countOnly=true${urlAdditions}`),
				{
					headers: this.defaultHeaders(),
				},
			);
			groupsCount = groupsResult?.data?.total ?? 0;
		}
		if (userResult?.data) {
			return userResult.data.total + groupsCount;
		}
		return 0;
	};

	getPathwaySectionsSentiments = async (pathwayId, pathwaySectionId) => {
		let urlAdditions = '&countOnly=true';
		if (pathwaySectionId) {
			urlAdditions = `${urlAdditions}&pathwaySectionId=${pathwaySectionId}`;
		}
		const greatResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/user_pathway_sections?moodEmotion=great${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const goodResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/user_pathway_sections?moodEmotion=good${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const okayResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/user_pathway_sections?moodEmotion=okay${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const badResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/user_pathway_sections?moodEmotion=bad${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const awfulResult = await this.httpClient.get(
			this.processPath(`/pathways/${pathwayId}/user_pathway_sections?moodEmotion=awful${urlAdditions}`),
			{
				headers: this.defaultHeaders(),
			},
		);
		const greatCount = greatResult?.data?.total ?? 0;
		const goodCount = goodResult?.data?.total ?? 0;
		const okayCount = okayResult?.data?.total ?? 0;
		const badCount = badResult?.data?.total ?? 0;
		const awfulCount = awfulResult?.data?.total ?? 0;
		const totalCount = greatCount + goodCount + okayCount + badCount + awfulCount;
		return {
			great: {
				count: greatCount,
				percentage: (greatCount / totalCount) * 100,
			},
			good: {
				count: goodCount,
				percentage: (goodCount / totalCount) * 100,
			},
			okay: {
				count: okayCount,
				percentage: (okayCount / totalCount) * 100,
			},
			bad: {
				count: badCount,
				percentage: (badCount / totalCount) * 100,
			},
			awful: {
				count: awfulCount,
				percentage: (awfulCount / totalCount) * 100,
			},
		};
	};

	getPathwayAnonymousResponses = async (pathwayId, pathwaySectionId, page, perPage) => {
		let url = `/pathways/${pathwayId}/user_pathway_sections?hasMoodBody=true&perPage=${perPage}&page=${page}`;
		if (pathwaySectionId) {
			url = `${url}&pathwaySectionId=${pathwaySectionId}`;
		}
		const responses = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return responses?.data;
	};

	getGroupedGenders = async () => {
		let url = '/user_metrics?metricType=gender';
		const response = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		const counts = [
			{
				count: response.data.data.female,
				name: 'Female',
			},
			{
				count: response.data.data.male,
				name: 'Male',
			},
			{
				count: response.data.data.undisclosed,
				name: 'Undisclosed',
			},
		];
		return counts;
	};

	getGroupedAges = async () => {
		let url = '/user_metrics?metricType=age';
		const response = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return response.data.data;
	};

	getAhoyMetricsCount = async (params) => {
		let url = '/ahoy_events';
		Object.keys(params).forEach((key, index) => {
			url = `${url}${index === 0 ? '?' : '&'}${key}=${params[key]}`;
		});
		const response = await this.httpClient.get(url, {
			headers: this.defaultHeaders(),
		});

		// Check if response and response.data are defined
		if (response && response.data) {
			return response.data.data;
		} else {
			// Handle the case where response or response.data is undefined
			console.error('Unexpected response structure:', response);
			return null; // or handle it in a way that makes sense for your application
		}
	};

	getAhoyGroupedCount = async (params) => {
		let url = '/ahoy_events/grouped_count';
		Object.keys(params).forEach((key, index) => {
			url = `${url}${index === 0 ? '?' : '&'}${key}=${params[key]}`;
		});
		const response = await this.httpClient.get(url, {
			headers: this.defaultHeaders(),
		});

		return response.data.data;
	};

	getAhoyCountGroupedByWeek = async (params) => {
		let url = '/ahoy_events/grouped_by_week';
		Object.keys(params).forEach((key, index) => {
			url = `${url}${index === 0 ? '?' : '&'}${key}=${params[key]}`;
		});
		const response = await this.httpClient.get(url, {
			headers: this.defaultHeaders(),
		});
		return response.data.data;
	};

	getAhoyCountGroupedByMonth = async (params) => {
		let url = '/ahoy_events/grouped_by_month';
		Object.keys(params).forEach((key, index) => {
			url = `${url}${index === 0 ? '?' : '&'}${key}=${params[key]}`;
		});
		const response = await this.httpClient.get(url, {
			headers: this.defaultHeaders(),
		});
		return response.data.data;
	};

	getUserCourseStats = async (courseId, page, perPage) => {
		const url = `/user_course_stats/${courseId}?page=${page}&perPage=${perPage}`;
		const response = await this.httpClient.get(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return response.data;
	};

	addPathwaySchedule = async (pathwayId, options) => {
		const url = `/pathways/${pathwayId}/schedules`;
		const response = await this.httpClient.post(this.processPath(url), options, {
			headers: this.defaultHeaders(),
		});
		return response.data;
	};

	addCourseSchedule = async (courseId, options) => {
		const url = `/courses/${courseId}/group_courses`;
		const response = await this.httpClient.post(this.processPath(url), options, {
			headers: this.defaultHeaders(),
		});
		return response.data;
	};

	addUserCourseSchedule = async (courseId, options) => {
		const url = `/courses/${courseId}/user_courses`;
		const response = await this.httpClient.post(this.processPath(url), options, {
			headers: this.defaultHeaders(),
		});
		return response.data;
	};

	deleteGroupCourseSchedule = async (courseId, groupCourseId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/courses/${courseId}/group_courses/${groupCourseId}`),
			{ headers: this.defaultHeaders() },
		);
		return result && result.data;
	};

	deleteUserCourseSchedule = async (courseId, userCourseId) => {
		const result = await this.httpClient.delete(
			this.processPath(`/courses/${courseId}/user_courses/${userCourseId}`),
			{ headers: this.defaultHeaders() },
		);
		return result && result.data;
	};

	deletePathwaySchedule = async (pathwayId, scheduleId, userId) => {
		const url = `/pathways/${pathwayId}/schedules/${scheduleId}?userId=${userId}`;
		const response = await this.httpClient.delete(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return response.data;
	};

	deleteGroupPathwaySchedule = async (pathwayId, scheduleId, groupId) => {
		const url = `/pathways/${pathwayId}/schedules/${scheduleId}?groupId=${groupId}`;
		const response = await this.httpClient.delete(this.processPath(url), {
			headers: this.defaultHeaders(),
		});
		return response.data;
	};
}

const tetherApi = observer(new TetherApi());

export default tetherApi;
