import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Confirm from '../Confirm';
import { humanize } from '../../utils/stringUtils';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import Pagination from '../Pagination';
import { statusPill } from '../../utils/pillHelper';
import { statusLabels } from '../../utils/humanReadableLabels';

function PathwayForm() {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			colorHue: 200,
			frequency: 'weekly',
			mode: 'no_start_date',
			accessRole: null,
		},
	});

	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [authors, setAuthors] = useState([]);
	const [pathwayTypes, setPathwayTypes] = useState({});
	const { pathwayId } = useParams();
	const [pathway, setPathway] = useState(null);
	const [pathwaySections, setPathwaySections] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [duplicateConfirm, setDuplicateConfirm] = useState(false);
	const [unmonitoredFieldChanged, setUnmonitoredFieldChanged] = useState(false);

	let navigate = useNavigate();

	const watchMode = watch('mode');
	const watchStartDate = watch('startDate');

	useEffect(() => {
		if (!authors.length) {
			fetchAuthors();
		}

		if (pathway && !pathwaySections) {
			fetchPathwaySections();
		}

		if (!Object.keys(pathwayTypes).length) {
			fetchPathwayTypes();
		}

		if (pathwayId && !pathway && authors.length && Object.keys(pathwayTypes).length && orgsStore.orgs.length) {
			fetchPathway(pathwayId);
		}

		setSuccessMessage(pathwayId ? 'Plan updated!' : 'Plan created!');
	}, [authors, pathwayTypes, pathway, pathwaySections, page, orgsStore.currentOrg, deleteConfirm, duplicateConfirm]);
	let tier = orgsStore.getOrgTier();

	const fetchAuthors = async () => {
		const authorsResponse = await tetherApi.getAuthors();

		setAuthors(authorsResponse.data);
	};

	const fetchPathwayTypes = async () => {
		const pathwayTypesResponse = await tetherApi.getPathwayTypes();

		setPathwayTypes(pathwayTypesResponse.data);
	};

	const fetchPathway = async (pathwayId) => {
		const pathwayResponse = await tetherApi.getPathway(pathwayId);

		setPathway(pathwayResponse.data);

		reset(pathwayResponse.data);
	};

	const fetchPathwaySections = async () => {
		const pathwaySectionsResponse = await tetherApi.getPathwaySections(pathwayId, page, perPage);

		setPathwaySections(pathwaySectionsResponse.data);

		const totalCount = pathwaySectionsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);

		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + pathwaySectionsResponse.data.length);
	};

	const renderIsPublic = () => {
		if (orgsStore.currentOrg?.isPublisher === true) {
			return (
				// <div className="col-span-full">
				// 	<label
				// 		htmlFor="isPublic"
				// 		className="block text-sm font-medium leading-6 text-gray-900"
				// 	>
				// 		Public
				// 	</label>
				// 	<div className="mt-2">
				// 		<input
				// 			{...register('isPublic')}
				// 			type="checkbox"
				// 			className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300  rounded font-regular"
				// 		/>
				// 		<p className="mt-2 text-sm text-gray-500  font-regular">
				// 			Select if you want the plan to be publicly available to <strong>all</strong> users on Tether
				// 		</p>
				// 	</div>
				// </div>
				<div className="relative flex gap-x-3">
					<div className="flex h-6 items-center">
						<input
							{...register('isPublic')}
							type="checkbox"
							className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
						/>
					</div>
					<div className="text-sm leading-6">
						<label htmlFor="candidates" className="font-medium text-gray-900">
							Public
						</label>
						<p className="text-gray-500">Publish to global content library</p>
					</div>
				</div>
			);
		}
	};

	const reducePathwaySectionIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var pathwaySection = pathwaySections[parseInt(currentIndex)];

		await tetherApi.updatePathwaySection(pathwayId, pathwaySection.id, {
			index: pathwaySection.index - 1,
		});

		fetchPathwaySections();
	};

	const increasePathwaySectionIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var pathwaySection = pathwaySections[parseInt(currentIndex)];

		await tetherApi.updatePathwaySection(pathwayId, pathwaySection.id, {
			index: pathwaySection.index + 1,
		});

		fetchPathwaySections();
	};

	const onSubmit = async (data) => {
		const result = await submitPathway(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/plans');
		}
	};

	const submitPathway = async (data) => {
		const formData = new FormData(document.getElementById('pathway-form'));
		if (data.image[0]) {
			formData.append('image', data.image[0]);
		}

		if (data.file[0]) {
			formData.append('file', data.file[0]);
		}
		formData.append('featured', data.featured);
		formData.append('isMembersOnly', data.isMembersOnly);
		formData.append('sortInverse', data.sortInverse);
		formData.append('preventUserStart', data.preventUserStart);
		formData.append('enforceSectionSchedule', data.enforceSectionSchedule);
		formData.append('colorHue', 200);

		if (data.pathwayType === '' || data.pathwayType === null) {
			formData.delete('pathwayType');
		}

		if (data.mode === 'unlocked' || data.mode === 'no_start_date') {
			formData.delete('startDate');
		}

		if (data.id) {
			return await tetherApi.updatePathway(data.id, formData);
		} else {
			return await tetherApi.createPathway(formData);
		}
	};

	const deletePathway = async () => {
		const result = await tetherApi.deletePathway(pathwayId);

		if (result) {
			alertService.success('Plan deleted');

			navigate('/plans');
		}
	};

	const duplicatePathway = async () => {
		const formData = new FormData(document.getElementById('pathway-form'));
		const result = await tetherApi.duplicatePathway(pathwayId, formData);

		if (result) {
			alertService.success('Plan duplicated');

			navigate('/plans');
		}
	};

	return (
		<form className="p-12 space-y-8" id="pathway-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 sm:space-y-5">
				<div>
					<div>
						<h3 className="text-xl font-semibold text-gray-900">{pathwayId ? '' : 'New Plan'}</h3>
					</div>
					<div className="flex flex-row justify-between items-center w-full">
						<div>
							<span
								className={
									'inline-flex items-center rounded-md px-2.5 py-0.5 mb-2 text-sm font-medium ' +
									statusPill(pathway?.status)
								}
							>
								{statusLabels[pathway?.status] || ''}
							</span>
							<h3 className="text-3xl font-semibold text-gray-900 max-w-2xl truncate">{pathway?.name}</h3>
						</div>
						<div>
							<div>
								{!!pathway?.webUrl && (
									<div>
										<button
											type="button"
											onClick={() => setDuplicateConfirm(true)}
											className={
												pathwayId
													? 'justify-center py-2 px-4 text-sm font-medium rounded-md text-neutral-800 bg-white shadow-sm border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
													: 'hidden'
											}
										>
											Duplicate
										</button>
										<button
											type="button"
											onClick={() => navigator.clipboard.writeText(pathway?.webUrl)}
											className="cursor-pointer disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
										>
											Copy URL
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Access</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select whether to publish or keep the content private and only access if you have the
								link.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
									Status*
								</label>
								<div className="mt-2">
									<select
										{...register('status', { required: 'Status is required' })}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular"
									>
										<option key="status-draft" value="draft">
											Draft
										</option>
										<option key="status-published" value="published">
											Published
										</option>
										<option key="status-published-private" value="published-private">
											Private
										</option>
									</select>
									<p
										className={errors.status?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="status-error"
									>
										{errors.status?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="accessRole"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									User Role
								</label>
								<div className="mt-2">
									<select
										{...register('accessRole')}
										disabled={pathway?.groupPathwaysExist}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular"
									>
										<option key="access-role-all" value="">
											All Members
										</option>
										<option key="access-role-org-owner" value="org_owner">
											Administrators
										</option>
										<option key="access-role-group-leader" value="group_leader">
											Moderators
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500 font-regular">
										Becomes locked after the plan is started or the date has passed
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name, description, and author.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('name', {
											required: 'Name is required',
											maxLength: 'Name must be 255 characters or less',
										})}
										type="text"
										placeholder="Plan Name"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md font-regular"
									/>
									<p
										className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="name-error"
									>
										{errors.name?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="subtitle" className="block text-sm font-medium leading-6 text-gray-900">
									Subtitle
								</label>
								<div className="mt-2">
									<input
										{...register('subtitle')}
										type="text"
										name="subtitle"
										placeholder="Plan Description"
										id="subtitle"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="overview" className="block text-sm font-medium leading-6 text-gray-900">
									Overview*
								</label>
								<div className="mt-2">
									<textarea
										{...register('overview', {
											required: 'Overview is required',
										})}
										rows={5}
										placeholder="Plan Overview"
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.overview?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="overview-error"
									>
										{errors.overview?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="authorId" className="block text-sm font-medium leading-6 text-gray-900">
									Author*
								</label>
								<div className="mt-2">
									<select
										{...register('authorId', { required: 'Author is required' })}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="author-blank" value="">
											Select an Author...
										</option>
										{authors.map((author) => (
											<option key={`author-${author.id}`} value={author.id}>
												{author.name}
											</option>
										))}
									</select>
									<p
										className={errors.author?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="author-error"
									>
										{errors.author?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Brand</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the brand details like image, category, and type.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="pathwayType"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Category
								</label>
								<div className="mt-2">
									<select
										{...register('pathwayType')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="pathway-type-blank" value="">
											Select a Category...
										</option>
										{Object.entries(pathwayTypes).map(([key, value]) => (
											<option key={`pathwayType-${key}`} value={value}>
												{humanize(value)}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="cover-photo"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Rectangle Image - (1800 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('image')}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>
									<div className={pathway?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={pathway?.imageUrl}
											alt=""
											className={pathway?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="cover-photo"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Square Image - (1080 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('secondaryImage')}
										type="file"
										name="secondaryImage"
										id="secondaryImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>
									<div className={pathway?.secondaryImageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={pathway?.secondaryImageUrl}
											alt=""
											className={pathway?.secondaryImageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
									File
								</label>
								<div className="mt-2">
									<input
										{...register('file')}
										type="file"
										name="file"
										id="file"
										accept="application/pdf"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  "
									/>
									<p className="mt-2 text-sm text-gray-500 ">Accepts .pdf</p>
								</div>
								<div className={pathway?.fileUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<a href={pathway?.fileUrl} className={pathway?.fileUrl ? '' : 'hidden'}>
										View File
									</a>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Schedule</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the schedule, time, and duration.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="mode" className="block text-sm font-medium leading-6 text-gray-900">
									Type
								</label>
								<div className="mt-2">
									<select
										{...register('mode')}
										disabled={pathway?.groupPathwaysExist}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="pathway-mode-start-date" value="start_date">
											Scheduled (Start Date)
										</option>
										<option key="pathway-mode-no-start-date" value="no_start_date">
											Structured (No Start Date)
										</option>
										<option key="pathway-mode-unlocked" value="unlocked">
											Unlocked (Always Available)
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Becomes locked after the plan is started or the date has passed
									</p>
								</div>
							</div>
							<div className={watchMode === 'start_date' ? 'col-span-full' : 'hidden'}>
								<label
									htmlFor="startDate"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Start Date*
								</label>
								<div className="mt-2">
									<ReactDatePicker
										{...register('startDate', {
											required: watchMode === 'start_date' ? 'Start Date is required' : false,
										})}
										disabled={pathway?.groupPathwaysExist}
										dateFormat={'MMMM d, yyyy'}
										selected={watchStartDate ? moment(watchStartDate).toDate() : null}
										onChange={(date) => {
											setValue('startDate', date);
											setUnmonitoredFieldChanged(true);
										}}
										calendarClassName="datepicker-calendar"
										type="text"
										autocomplete="off"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Start Date cannot be changed after the plan has been started
									</p>
									<p className="mt-2 text-sm text-red-600">{errors.startDate?.message}</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="frequency"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Frequency*
								</label>
								<div className="mt-2">
									<select
										{...register('frequency', {
											required: 'Frequency is required',
										})}
										disabled={pathway?.groupPathwaysExist}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="frequency-daily" value="daily">
											Daily
										</option>
										<option key="frequency-weekday" value="weekday">
											Weekday
										</option>
										<option key="frequency-weekly" value="weekly">
											Weekly
										</option>
										<option key="frequency-biweekly" value="biweekly">
											Bi-Weekly
										</option>
										<option key="frequency-monthly" value="monthly">
											Monthly
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Frequency can't be changed after the plan has been started
									</p>
									<p className="mt-2 text-sm text-red-600">{errors.frequency?.message}</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="frequencyAt"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Notification Time*
								</label>
								<div className="mt-2">
									<input
										{...register('frequencyAt', {
											required: 'Time is required',
										})}
										type="text"
										placeholder="E.g. 14:30"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md  font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">Use military time</p>
									<p
										className={errors.frequencyAt?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="frequencyAt-error"
									>
										{errors.frequencyAt?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Options</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select from a range of options including whether to feature or restrict content.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('featured')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Featured
										</label>
										<p className="text-gray-500">Feature this content</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isMembersOnly')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Members Only
										</label>
										<p className="text-gray-500">Restrict to users with accounts</p>
									</div>
								</div>
							</div>
							<div className={watchMode === 'start_date' ? 'hidden' : 'col-span-full'}>
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('sortInverse')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Sort
										</label>
										<p className="text-gray-500">
											Display the content to users in the opposite order
										</p>
									</div>
								</div>
							</div>
							<div className={watchMode === 'start_date' ? 'hidden' : 'col-span-full'}>
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('preventUserStart')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Restrict Access
										</label>
										<p className="text-gray-500">
											Let users view the plan overview but not start it.
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">{renderIsPublic()}</div>
							{!!pathway?.webUrl && (
								<div className="col-span-full pl-7">
									<label
										htmlFor="photo"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Lock Content?
									</label>
									<div className="mt-2 flex items-center gap-x-3">
										{tier === 'basic' ? (
											<div
												type="button"
												className={
													isSubmitting
														? 'hidden'
														: 'bg-gray-100 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-400 cursor-not-allowed'
												}
											>
												Access Codes
											</div>
										) : (
											<Link
												to={'access_codes'}
												type="button"
												className={
													isSubmitting
														? 'hidden'
														: 'bg-white py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
												}
											>
												Access Codes
											</Link>
										)}
										{/* <Link
											to={'access_codes'}
											type="button"
											className={
												isSubmitting
													? 'hidden'
													: 'bg-white py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
											}
										>
											Access Codes
										</Link> */}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							pathwayId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>
					<Link
						type="button"
						to="/plans"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					{isDirty || unmonitoredFieldChanged ? (
						<button
							type="submit"
							disabled={isSubmitting}
							className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							Save
						</button>
					) : pathway?.webUrl ? (
						<div
							onClick={() => window.open(pathway.webUrl)}
							className="cursor-pointer ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							{pathway?.status === 'draft' ? 'Preview' : 'Visit'}
						</div>
					) : (
						<div />
					)}
				</div>
			</div>

			<div className={pathwayId ? '' : 'hidden'}>
				<div className="mt-16 space-y-8 sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-3xl font-semibold text-gray-900">Sections</h1>
						</div>
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to={`/plans/${pathway?.id}/plan_sections/new`}
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							>
								New Section
							</Link>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Subtitle
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Overview
											</th>
											{(!orgsStore?.currentOrg || orgsStore.currentOrg?.isPublisher) && (
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Stats
												</th>
											)}
											{(!orgsStore?.currentOrg || pathway?.status !== 'draft') && (
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													PDF
												</th>
											)}
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{pathwaySections?.map((pathwaySection, i) => {
											return (
												<tr
													key={pathwaySection.id}
													className={i % 2 === 0 ? undefined : 'bg-white'}
												>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
														<span
															className={pathway.status == 'published' ? 'hidden' : null}
														>
															<ArrowUpCircleIcon
																data-index={i}
																className={
																	pathwaySection.index === 0
																		? 'opacity-25 inline-block h-5 w-5 mr-4'
																		: 'cursor-pointer inline-block h-5 w-5 mr-4'
																}
																onClick={
																	pathwaySection.index === 0
																		? null
																		: reducePathwaySectionIndex
																}
															/>
															<ArrowDownCircleIcon
																data-index={i}
																className={
																	pathwaySection.index ===
																	pathwaySection?.pathwaySectionSteps?.length - 1
																		? 'opacity-25 inline-block h-5 w-5 mr-4'
																		: 'cursor-pointer inline-block h-5 w-5 mr-4'
																}
																onClick={
																	pathwaySection.index ===
																	pathway?.pathwaySections?.length - 1
																		? null
																		: increasePathwaySectionIndex
																}
															/>
														</span>
														{pathwaySection.name}
													</td>

													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs truncate">
														{pathwaySection.subtitle}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs truncate">
														{pathwaySection.overview}
													</td>
													{(!orgsStore?.currentOrg || orgsStore.currentOrg?.isPublisher) && (
														<td className="relative py-4 pl-3 text-left text-sm font-medium sm:pr-6">
															<Link
																to={`/plans/${pathwaySection.pathwayId}/plan_sections/${pathwaySection.id}/stats`}
																className="text-neutral-800 hover:text-neutral-900"
															>
																Stats
																<span className="sr-only">, {pathwaySection.name}</span>
															</Link>
														</td>
													)}
													{(!orgsStore?.currentOrg || pathway?.status !== 'draft') && (
														<td className="relative py-4 pl-3 text-left text-sm font-medium sm:pr-6">
															<Link
																to={`https://tether-rails-production.herokuapp.com/api/v3/pathways/${pathwaySection.pathwayId}/pathway_sections/${pathwaySection.id}.pdf`}
																className="font-medium text-neutral-800 hover:text-neutral-900"
															>
																Download PDF
																<span className="sr-only">, {pathwaySection.name}</span>
															</Link>
														</td>
													)}
													<td className="relative py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
														<Link
															to={`/plans/${pathwaySection.pathwayId}/plan_sections/${pathwaySection.id}`}
															className="font-medium text-neutral-800 hover:text-neutral-900"
														>
															Edit
															<span className="sr-only">, {pathwaySection.name}</span>
														</Link>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<Pagination
					page={page}
					setPage={setPage}
					numPages={numPages}
					startIndex={startIndex}
					endIndex={endIndex}
					total={total}
					onPageChange={() => {
						setPathwaySections(null);
					}}
				/>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Plan"
				body="This will delete all content for the Plan and cannot be undone. Are you sure?"
				onConfirm={deletePathway}
				onCancel={() => setDeleteConfirm(false)}
			/>
			<Confirm
				isOpen={duplicateConfirm}
				title="Duplicate Plan"
				body="This will duplicate all content and create a new Plan. Are you sure?"
				onConfirm={duplicatePathway}
				onCancel={() => setDuplicateConfirm(false)}
			/>
		</form>
	);
}

export default observer(PathwayForm);
