import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { titleCase } from '../../utils/stringUtils';
import { ArrowUpCircleIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

function LeaderResourcesList() {
	const [leaderResources, setLeaderResources] = useState({});

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchLeaderResources();
		}
	}, [orgsStore.currentOrg]);

	const fetchLeaderResources = async () => {
		const leaderResourcesResponse = await tetherApi.getLeaderResources();

		setLeaderResources(leaderResourcesResponse.data);
	};

	const reduceFolderIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var leaderResourceFolder = leaderResources.leaderResourceFolders[parseInt(currentIndex)];

		await tetherApi.updateLeaderResourceFolder(leaderResourceFolder.id, {
			index: leaderResourceFolder.index - 1,
		});

		fetchLeaderResources();
	};

	const increaseFolderIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var leaderResourceFolder = leaderResources.leaderResourceFolders[parseInt(currentIndex)];

		await tetherApi.updateLeaderResourceFolder(leaderResourceFolder.id, {
			index: leaderResourceFolder.index + 1,
		});

		fetchLeaderResources();
	};

	const reduceResourceIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var leaderResource = leaderResources.leaderResources[parseInt(currentIndex)];

		await tetherApi.updateLeaderResource(leaderResource.id, {
			index: leaderResource.index - 1,
		});

		fetchLeaderResources();
	};

	const increaseResourceIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var leaderResource = leaderResources.leaderResources[parseInt(currentIndex)];

		await tetherApi.updateLeaderResource(leaderResource.id, {
			index: leaderResource.index + 1,
		});

		fetchLeaderResources();
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Resources</h1>
					<p className="text-sm text-gray-500">Upload files, or link to videos, podcasts, and more</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://intercom.help/withtether/en/articles/9535087-about-resources"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link>
					<Link
						to="/resources/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Resource
					</Link>
				</div>
			</div>

			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{leaderResources?.leaderResources?.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No uploads to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No uploads are available.
										</p>
										<Link
											to="/resources/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New File
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Featured
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Members Only
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Type
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Folder
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Last Updated
											</th>
											{/* <th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-regular text-gray-900"
										>
											Stats
										</th> */}
											{/* <th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th> */}
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{leaderResources?.leaderResources?.map((leaderResource, i) => (
											<tr
												key={leaderResource.id}
												className={i % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													<ArrowUpCircleIcon
														data-index={i}
														className={(() => {
															if (leaderResource.leaderResourceFolderId) {
																return 'invisible inline-block h-5 w-5 mr-4';
															} else if (leaderResource.index === 0) {
																return 'opacity-25 inline-block h-5 w-5 mr-4';
															} else {
																return 'cursor-pointer inline-block h-5 w-5 mr-4';
															}
														})()}
														onClick={
															leaderResource.leaderResourceFolderId ||
															leaderResource.index === 0
																? null
																: reduceResourceIndex
														}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={(() => {
															if (leaderResource.leaderResourceFolderId) {
																return 'invisible inline-block h-5 w-5 mr-4';
															} else if (
																i ===
																leaderResources.leaderResources.length - 1
															) {
																return 'opacity-25 inline-block h-5 w-5 mr-4';
															} else {
																return 'cursor-pointer inline-block h-5 w-5 mr-4';
															}
														})()}
														onClick={
															leaderResource.leaderResourceFolderId ||
															i === leaderResources.leaderResources.length - 1
																? null
																: increaseResourceIndex
														}
													/>
													{leaderResource.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{leaderResource.isFeatured ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{leaderResource.isMembersOnly ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{titleCase(leaderResource.resourceType)}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{leaderResource.leaderResourceFolder?.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(leaderResource.updatedAt).format('LL')}
												</td>
												{/* {(!orgsStore?.currentOrg || orgsStore.currentOrg?.isPublisher) && (
												<td className="relative py-4 pl-3 text-sm font-medium">
													<Link
														to={`/leader_resources/${leaderResource.id}/stats`}
														className="text-neutral-800 hover:text-neutral-900"
													>
														Stats<span className="sr-only">, {leaderResource.name}</span>
													</Link>
												</td>
											)} */}
												<td className="flex justify-end relative whitespace-nowrap py-3 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/resources/${leaderResource.id}`}
														className="inline-flex rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:mt-4 lg:mt-0"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="sm:flex sm:items-center mt-8">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Folders</h1>
					<p className="text-sm text-gray-500">Organize your resources in folders</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/resource_folders/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Folder
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{leaderResources?.leaderResourceFolders?.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No folders to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No folders are available.
										</p>
										<Link
											to="/leader_resource_folders/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Folder
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{leaderResources?.leaderResourceFolders?.map((leaderResourceFolder, i) => (
											<tr
												key={leaderResourceFolder.id}
												className={i % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceFolderIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === leaderResources.leaderResourceFolders.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === leaderResources.leaderResourceFolders.length - 1
																? null
																: increaseFolderIndex
														}
													/>

													{leaderResourceFolder.title}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/resource_folders/${leaderResourceFolder.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(LeaderResourcesList);
