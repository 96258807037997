export const stepTypeLabels = {
	audio: 'Audio File',
	image: 'Image (Portrait)',
	'image-landscape': 'Image (Landscape)',
	file: 'PDF',
	html: 'Text Editor',
	link: 'Link',
	list: 'Bulleted List',
	'multiple-choice': 'Multiple Choice',
	practice: 'Practice',
	quote: 'Quote',
	scripture: 'Scripture',
	text: 'Small Text',
	'text-response': 'Short Answer',
	video: 'Video (Portrait)',
	'video-landscape': 'Video (Landscape)',
	timer: 'Timer',
};

export const accessRoleLabels = {
	org_owner: 'Administrators',
	group_leader: 'Moderators',
};

export const statusLabels = {
	published: 'Published',
	draft: 'Draft',
	'published-private': 'Private',
};

export const guideLabels = {
	start_date: 'Scheduled',
	unlocked: 'Unlocked',
	no_start_date: 'On Demand',
};
