import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import Pagination from '../Pagination';
import { Copy } from 'lucide-react';

function PodcastsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [podcasts, setPodcasts] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchPodcasts();
	}, [page]);

	const fetchPodcasts = async () => {
		try {
			const podcastsResponse = await tetherApi.getPodcasts(page, perPage);

			if (podcastsResponse && podcastsResponse.data) {
				setPodcasts(podcastsResponse.data);

				const totalCount = podcastsResponse.total;
				const pagesCount = Math.ceil(totalCount / perPage);

				setTotal(totalCount);
				setNumPages(pagesCount);
				setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
				setEndIndex(perPage * (page - 1) + podcastsResponse.data.length);
			} else {
				throw new Error('Invalid response format');
			}
		} catch (err) {
			console.error('Failed to fetch podcasts:', err);
			setError('Failed to load podcasts. Please try again later.');
		}
	};

	const handleCopy = (text) => {
		navigator.clipboard.writeText(text);
		alert('Link copied to clipboard!');
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Podcasts</h1>
					<p className="text-sm text-gray-500">Host a podcast and generate a RSS feed for your audience</p>
				</div>
				<div className="xs:mt-0 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="/podcasts/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:w-full xs:mt-4 lg:mt-0"
					>
						New Podcast
					</Link>
				</div>
			</div>
			{error ? (
				<div className="mt-4 text-red-600">{error}</div>
			) : (
				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								{podcasts.length === 0 ? (
									<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
										<div className="text-center">
											<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
												No podcasts to display
											</p>
											<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
												No podcasts are available.
											</p>
											<Link
												to="/plans/new"
												className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
											>
												Add Podcast
											</Link>
										</div>
									</div>
								) : (
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-white">
											<tr>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Artwork
												</th>
												<th
													scope="col"
													className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
												>
													Title
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Description
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{podcasts.map((podcast, podcastIdx) => (
												<tr
													key={podcast.id}
													className={podcastIdx % 2 === 0 ? undefined : 'bg-white'}
												>
													<td className="whitespace-nowrap py-4 text-sm text-gray-500">
														<img
															className="w-8 h-8"
															alt={podcast.title}
															src={podcast.imageUrl}
														/>
													</td>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
														{podcast.title}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{podcast.description}
													</td>
													<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
														<div className="flex items-center space-x-2">
															<a
																href={podcast.podcastUrl || '#'}
																target="_blank"
																rel="noopener noreferrer"
																className="text-black font-medium hover:underline hover:decoration-black"
															>
																{(podcast.podcastUrl || '').replace(/^https?:\/\//, '')}
															</a>
															<button
																onClick={() => handleCopy(podcast.podcastUrl)}
																className="text-black hover:bg-gray-100 hover:rounded-full p-2"
															>
																<Copy color="black" size={16} />
															</button>
														</div>
													</td>
													<td className="flex justify-end relative whitespace-nowrap py-3 text-sm text-neutral-800 hover:text-neutral-900">
														<Link
															to={`/podcasts/${podcast.id}/episodes`}
															className="inline-flex rounded-lg border border-gray-300 text-black bg-white px-4 py-2 text-sm font-medium shadow-sm mr-2 xs:mt-4 lg:mt-0"
														>
															Episodes
														</Link>
														<Link
															to={`/podcasts/${podcast.id}`}
															className="inline-flex rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:mt-4 lg:mt-0"
														>
															Manage
														</Link>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(PodcastsList);
