import React, { Fragment, useState, useEffect } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import tetherApi from '../../api/tether';
import { XCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function UserSearch({
	isOpen = false,
	actionLabel = null,
	onActionClick = null,
	onCancel = null,
	roles = null,
}) {
	const [query, setQuery] = useState(null);
	const [queryRoles] = useState(roles);
	const [users, setUsers] = useState([]);
	const [noUsersFound, setNoUsersFound] = useState(false);

	const fetchUsers = async (query) => {
		const usersResponse = await tetherApi.searchUsers(query, {
			roles: queryRoles,
		});

		setNoUsersFound((usersResponse?.data?.length || 0) === 0);

		setUsers(usersResponse.data);
	};

	const handleQueryChange = (event) => {
		setNoUsersFound(false);

		setQuery(event.target.value);

		fetchUsers(query);
	};

	useEffect(() => {
		if (query && !users.length && !noUsersFound) {
			fetchUsers();
		}
	}, [query, users, noUsersFound]);

	return (
		<Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery(null)} appear>
			<Dialog as="div" className="relative z-10" onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-50 bg-opacity-25 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-gray-500 ring-opacity-5 transition-all">
							<Combobox onChange={(person) => (window.location = person.url)}>
								<div className="relative">
									<MagnifyingGlassIcon
										className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
									<Combobox.Input
										className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm font-regular"
										placeholder="Search Users..."
										onChange={handleQueryChange}
									/>

									<XCircleIcon
										className="absolute right-4 top-4 pointer h-5 w-5 text-gray-400"
										onClick={onCancel}
									/>
								</div>

								{users.length > 0 && (
									<Combobox.Options
										static
										className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
									>
										{users.map((user) => (
											<Combobox.Option
												key={user.id}
												value={user}
												onClick={(event) => {
													event.preventDefault();
												}}
												className={({ active }) =>
													classNames(
														'cursor-default select-none px-4 py-2',
														active && 'bg-gray-50',
													)
												}
											>
												<span className="inline-block w-8/12">
													{user.firstName} {user.lastName}
												</span>
												<span className="inline-block w-8/12">{user.email}</span>
												<span className="inline-block w-4/12 text-right">
													<button
														className="right disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
														data-user-id={user.id}
														onClick={onActionClick}
													>
														{actionLabel}
													</button>
												</span>
											</Combobox.Option>
										))}
									</Combobox.Options>
								)}

								{query !== '' && users.length === 0 && (
									<p className="p-4 text-sm text-gray-500 font-regular">No Users Found</p>
								)}
							</Combobox>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
