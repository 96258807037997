import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';

function PodcastsForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm();

	const { podcastId } = useParams();
	const [podcast, setPodcast] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	const categories = [
		'Arts',
		'Arts :: Books',
		'Arts :: Design',
		'Arts :: Fashion & Beauty',
		'Arts :: Food',
		'Arts :: Performing Arts',
		'Arts :: Visual Arts',
		'Business',
		'Business :: Careers',
		'Business :: Entrepreneurship',
		'Business :: Investing',
		'Business :: Management',
		'Business :: Marketing',
		'Business :: Non-Profit',
		'Comedy',
		'Comedy :: Comedy Interviews',
		'Comedy :: Improv',
		'Comedy :: Stand-Up',
		'Education',
		'Education :: Courses',
		'Education :: How To',
		'Education :: Language Learning',
		'Education :: Self-Improvement',
		'Fiction',
		'Fiction :: Comedy Fiction',
		'Fiction :: Drama',
		'Fiction :: Science Fiction',
		'Government',
		'History',
		'Health & Fitness',
		'Health & Fitness :: Alternative Health',
		'Health & Fitness :: Fitness',
		'Health & Fitness :: Medicine',
		'Health & Fitness :: Mental Health',
		'Health & Fitness :: Nutrition',
		'Health & Fitness :: Sexuality',
		'Kids & Family',
		'Kids & Family :: Education for Kids',
		'Kids & Family :: Parenting',
		'Kids & Family :: Pets & Animals',
		'Kids & Family :: Stories for Kids',
		'Leisure',
		'Leisure :: Animation & Manga',
		'Leisure :: Automotive',
		'Leisure :: Aviation',
		'Leisure :: Crafts',
		'Leisure :: Games',
		'Leisure :: Hobbies',
		'Leisure :: Home & Garden',
		'Leisure :: Video Games',
		'Music',
		'Music :: Music Commentary',
		'Music :: Music History',
		'Music :: Music Interviews',
		'News',
		'News :: Business News',
		'News :: Daily News',
		'News :: Entertainment News',
		'News :: News Commentary',
		'News :: Politics',
		'News :: Sports News',
		'News :: Tech News',
		'Religion & Spirituality',
		'Religion & Spirituality :: Buddhism',
		'Religion & Spirituality :: Christianity',
		'Religion & Spirituality :: Hinduism',
		'Religion & Spirituality :: Islam',
		'Religion & Spirituality :: Judaism',
		'Religion & Spirituality :: Religion',
		'Religion & Spirituality :: Spirituality',
		'Science',
		'Science :: Astronomy',
		'Science :: Chemistry',
		'Science :: Earth Sciences',
		'Science :: Life Sciences',
		'Science :: Mathematics',
		'Science :: Natural Sciences',
		'Science :: Nature',
		'Science :: Physics',
		'Science :: Social Sciences',
		'Society & Culture',
		'Society & Culture :: Documentary',
		'Society & Culture :: Personal Journals',
		'Society & Culture :: Philosophy',
		'Society & Culture :: Places & Travel',
		'Society & Culture :: Relationships',
		'Sports',
		'Sports :: Baseball',
		'Sports :: Basketball',
		'Sports :: Cricket',
		'Sports :: Fantasy Sports',
		'Sports :: Football',
		'Sports :: Golf',
		'Sports :: Hockey',
		'Sports :: Rugby',
		'Sports :: Running',
		'Sports :: Soccer',
		'Sports :: Swimming',
		'Sports :: Tennis',
		'Sports :: Volleyball',
		'Sports :: Wilderness',
		'Sports :: Wrestling',
		'Technology',
		'True Crime',
		'TV & Film',
		'TV & Film :: After Shows',
		'TV & Film :: Film History',
		'TV & Film :: Film Interviews',
		'TV & Film :: Film Reviews',
		'TV & Film :: TV Reviews',
	];

	let navigate = useNavigate();

	useEffect(() => {
		if (podcastId && !podcast) {
			fetchPodcast(podcastId);
		}

		setSuccessMessage(podcastId ? 'Podcast updated!' : 'Podcast created!');
	}, [podcast]);

	const fetchPodcast = async (podcastId) => {
		const podcastResponse = await tetherApi.getPodcast(podcastId);

		setPodcast(podcastResponse.data);

		reset(podcastResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitPodcast(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/podcasts');
		}
	};

	const submitPodcast = async (data) => {
		const formData = new FormData(document.getElementById('podcast-form'));

		if (data.id) {
			return await tetherApi.updatePodcast(data.id, formData);
		} else {
			return await tetherApi.createPodcast(formData);
		}
	};

	const deletePodcast = async () => {
		const result = await tetherApi.deletePodcast(podcastId);

		if (result) {
			alertService.success('Podcast deleted');

			navigate('/podcasts');
		}
	};

	return (
		<form className="p-12 space-y-8" id="podcast-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 sm:space-y-5">
				<div>
					<h3 className="mb-6 text-3xl font-semibold text-gray-900">{podcastId ? 'Edit' : 'New'} Podcast</h3>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the title, description, and other details.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Title*
								</label>
								<div className="mt-2">
									<input
										{...register('title', {
											required: 'Title is required',
											maxLength: 'Title must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="description"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Description*
								</label>
								<div className="mt-2">
									<textarea
										{...register('description', {
											required: 'Description is required',
										})}
										rows={5}
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
									/>
									<p
										className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="description-error"
									>
										{errors.description?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="artist" className="block text-sm font-medium leading-6 text-gray-900">
									Artist*
								</label>
								<div className="mt-2">
									<input
										{...register('artist', {
											required: 'Artist is required',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.artist?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="artist-error"
									>
										{errors.artist?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="podcast_type"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Podcast Type*
								</label>
								<div className="mt-2">
									<select
										{...register('podcast_type', {
											required: 'Podcast type is required',
										})}
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									>
										<option value="">Select a type</option>
										<option value="Episodic">Episodic</option>
										<option value="Serial">Serial</option>
									</select>
									<p
										className={
											errors.podcast_type?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="podcast-type-error"
									>
										{errors.podcast_type?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="podcast_category_name"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Category*
								</label>
								<div className="mt-2">
									<select
										{...register('podcast_category_name', {
											required: 'Category is required',
										})}
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									>
										<option value="">Select a category</option>
										{categories.map((category, index) => (
											<option key={index} value={category}>
												{category}
											</option>
										))}
									</select>
									<p
										className={
											errors.podcast_category_name?.message
												? 'mt-2 text-sm text-red-600'
												: 'hidden'
										}
										id="podcast-category-error"
									>
										{errors.podcast_category_name?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="explicit" className="block text-sm font-medium leading-6 text-gray-900">
									Explicit Content
								</label>
								<div className="mt-2">
									<input
										{...register('explicit')}
										type="checkbox"
										className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
									/>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
									Website
								</label>
								<div className="mt-2">
									<input
										{...register('website', {
											pattern: {
												value: /^https?:\/\/.+/,
												message: 'Must be a valid URL',
											},
										})}
										type="url"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.website?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="website-error"
									>
										{errors.website?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="copyright"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Copyright
								</label>
								<div className="mt-2">
									<input
										{...register('copyright')}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="keywords" className="block text-sm font-medium leading-6 text-gray-900">
									Keywords*
								</label>
								<div className="mt-2">
									<input
										{...register('keywords', {
											pattern: {
												value: /^[a-zA-Z0-9, ]+$/,
												message: 'Must be a list of comma separated words',
											},
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.keywords?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="keywords-error"
									>
										{errors.keywords?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="language" className="block text-sm font-medium leading-6 text-gray-900">
									Language*
								</label>
								<div className="mt-2">
									<select
										{...register('language', {
											required: 'Language is required',
										})}
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									>
										<option value="">Select a language</option>
										{/* Add options for each language code */}
										<option value="en">English</option>
										<option value="es">Spanish</option>
										{/* Add more languages as needed */}
									</select>
									<p
										className={errors.language?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="language-error"
									>
										{errors.language?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Images</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">Edit the podcast image.</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Podcast Artwork*
								</label>
								<div className="mt-2">
									<input
										{...register('image', {
											required: !podcast?.imageUrl ? 'Image is required' : false,
										})}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300 file:text-gray-700 file:mr-4 max-w-lg block w-full border border-solid border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm"
									/>
									<p className="mt-2 text-sm text-gray-500">Accepts .png or .jpg</p>
									<p
										className={errors.image?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="image-error"
									>
										{errors.image?.message}
									</p>

									<div className={podcast?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={podcast?.imageUrl}
											alt=""
											className={podcast?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							podcastId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/podcasts"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Podcast"
				body="This will delete the Podcast and cannot be undone. Are you sure?"
				onConfirm={deletePodcast}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(PodcastsForm);
