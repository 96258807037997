import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import useRedirectToHttps from './hooks/useRedirectToHttps';
import LoginPage from './components/LoginPage';
import CreateAccountPage from './components/CreateAccountPage';
import CreateOrgPage from './components/CreateOrgPage';
import Layout from './components/Layout';
import Dashboard from './components/dashboard/Dashboard';
import PathwaysList from './components/pathways/PathwaysList';
import PathwayForm from './components/pathways/PathwayForm';
import PathwayStats from './components/pathways/PathwayStats';
import CourseList from './components/courses/CourseList';
import CourseForm from './components/courses/CourseForm';
import CourseStats from './components/courses/CourseStats';
import CourseSectionForm from './components/course_sections/CourseSectionForm';
import CourseSectionStats from './components/course_sections/CourseSectionStats';
import CourseSectionStepForm from './components/course_section_steps/CourseSectionStepForm';
import PracticesList from './components/practices/PracticesList';
import PracticeStats from './components/practices/PracticeStats';
import PracticeForm from './components/practices/PracticeForm';
import PracticeStepForm from './components/practice_steps/PracticeStepForm';
import PathwaySectionForm from './components/pathway_sections/PathwaySectionForm';
import PathwaySectionStats from './components/pathway_sections/PathwaySectionStats';
import PathwaySectionStepForm from './components/pathway_section_steps/PathwaySectionStepForm';
import PromptsList from './components/prompts/PromptsList';
import PromptForm from './components/prompts/PromptForm';
import AccessCodesList from './components/access_codes/AccessCodesList';
import AccessCodeForm from './components/access_codes/AccessCodeForm';
import AccessCodeRedemptionsList from './components/access_codes/AccessCodeRedemptionsList';
import OrgsList from './components/orgs/OrgsList';
import OrgForm from './components/orgs/OrgForm';
import FeaturesList from './components/features/FeaturesList';
import FeatureForm from './components/features/FeatureForm';
import CheckInPeriodImagesList from './components/check_in_period_images/CheckInPeriodImagesList';
import CheckInPeriodImageForm from './components/check_in_period_images/CheckInPeriodImageForm';
import AuthorsList from './components/authors/AuthorsList';
import AuthorForm from './components/authors/AuthorForm';
import GroupsList from './components/groups/GroupsList';
import GroupForm from './components/groups/GroupForm';
import GoalsList from './components/goals/GoalsList';
import GoalForm from './components/goals/GoalForm';
import LeaderResourcesList from './components/leader_resources/LeaderResourcesList';
import LeaderResourceForm from './components/leader_resources/LeaderResourceForm';
import LeaderResourceFolderForm from './components/leader_resources/LeaderResourceFolderForm';
import InvitationsList from './components/invitations/InvitationsList';
import InvitationForm from './components/invitations/InvitationForm';
import AssessmentsList from './components/assessments/AssessmentsList';
import AssessmentForm from './components/assessments/AssessmentForm';
import AssessmentQuestionForm from './components/assessment_questions/AssessmentQuestionForm';
import AssessmentQuestionAnswerForm from './components/assessment_question_answers/AssessmentQuestionAnswerForm';
import AssessmentActionLinkForm from './components/assessment_action_links/AssessmentActionLinkForm';
import AssessmentPeriodsList from './components/assessment_periods/AssessmentPeriodsList';
import ChannelsList from './components/channels/ChannelsList';
import ChannelForm from './components/channels/ChannelForm';
import ChallengesList from './components/challenges/ChallengesList';
import ChallengeForm from './components/challenges/ChallengeForm';
import AssessmentPeriodResults from './components/assessment_periods/AssessmentPeriodResults';
import CustomFieldsList from './components/custom_fields/CustomFieldsList';
import CustomFieldForm from './components/custom_fields/CustomFieldForm';
import UsersList from './components/users/UsersList';
import UserForm from './components/users/UserForm';
import CollectionsList from './components/collections/CollectionsList';
import CollectionForm from './components/collections/CollectionForm';
import CollectionItemForm from './components/collection_items/CollectionItemForm';
import BillingPage from './components/billing/BillingPage';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import OrgHabitsList from './components/org_habits/OrgHabitsList';
import OrgHabitForm from './components/org_habits/OrgHabitForm';
import IntegrationsIndex from './components/integrations/IntegrationsIndex';
import PlanningCenterCallback from './components/oauth/PlanningCenterCallback';
import StockImagesList from './components/stock_images/StockImagesList';
import StockImageForm from './components/stock_images/StockImageForm';
import ImportsList from './components/imports/ImportsList';
import ImportForm from './components/imports/ImportForm';
import ExportsList from './components/exports/ExportsList';
import GroupOrgForm from './components/groups/GroupOrgForm';
import OrgHabitStats from './components/org_habits/OrgHabitStats';
import LeaderResourceStats from './components/leader_resources/LeaderResourceStats';
import PodcastsList from './components/podcasts/PodcastsList';
import PodcastsForm from './components/podcasts/PodcastsForm';
import PodcastEpisodesList from './components/podcast_episodes/PodcastEpisodesList';
import PodcastEpisodesForm from './components/podcast_episodes/PodcastEpisodesForm';

if (!process.env.REACT_APP_AUTH0_REDIRECT_URI_LOGIN.includes('localhost')) {
	Sentry.init({
		dsn: 'https://72cf10659a674a3a9216aa8a6308cc0d@o516057.ingest.sentry.io/4504696761679872',
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.001,
	});
}

const App = () => {
	useRedirectToHttps();

	return (
		<Routes>
			<Route
				index
				element={
					<Layout>
						<Dashboard />
					</Layout>
				}
			/>
			<Route path="login" element={<LoginPage />} />
			{/* Create Account / Organization */}
			<Route path="create-account" element={<CreateAccountPage />} />
			<Route path="create-organization" element={<CreateOrgPage />} />
			<Route
				path="dashboard"
				element={
					<Layout>
						<Dashboard />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/access_codes"
				element={
					<Layout>
						<AccessCodesList />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/access_codes/:accessCodeId/redemptions"
				element={
					<Layout>
						<AccessCodeRedemptionsList />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/access_codes/new"
				element={
					<Layout>
						<AccessCodeForm />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/access_codes/:accessCodeId"
				element={
					<Layout>
						<AccessCodeForm />
					</Layout>
				}
			/>
			<Route
				path="plans"
				element={
					<Layout>
						<PathwaysList />
					</Layout>
				}
			/>
			<Route
				path="plans/new"
				element={
					<Layout>
						<PathwayForm />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId"
				element={
					<Layout>
						<PathwayForm />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/stats"
				element={
					<Layout>
						<PathwayStats />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/plan_sections/new"
				element={
					<Layout>
						<PathwaySectionForm />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/plan_sections/:pathwaySectionId"
				element={
					<Layout>
						<PathwaySectionForm />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/plan_sections/:pathwaySectionId/stats"
				element={
					<Layout>
						<PathwaySectionStats />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/plan_sections/:pathwaySectionId/plan_section_steps/new"
				element={
					<Layout>
						<PathwaySectionStepForm />
					</Layout>
				}
			/>
			<Route
				path="plans/:pathwayId/plan_sections/:pathwaySectionId/plan_section_steps/:pathwaySectionStepId"
				element={
					<Layout>
						<PathwaySectionStepForm />
					</Layout>
				}
			/>
			{/* <Route
				path="courses/:courseId/access_codes"
				element={
					<Layout>
						<AccessCodesList />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/access_codes/:accessCodeId/redemptions"
				element={
					<Layout>
						<AccessCodeRedemptionsList />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/access_codes/new"
				element={
					<Layout>
						<AccessCodeForm />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/access_codes/:accessCodeId"
				element={
					<Layout>
						<AccessCodeForm />
					</Layout>
				}
			/> */}
			<Route
				path="courses"
				element={
					<Layout>
						<CourseList />
					</Layout>
				}
			/>
			<Route
				path="courses/new"
				element={
					<Layout>
						<CourseForm />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId"
				element={
					<Layout>
						<CourseForm />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/stats"
				element={
					<Layout>
						<CourseStats />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/course_sections/new"
				element={
					<Layout>
						<CourseSectionForm />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/course_sections/:courseSectionId"
				element={
					<Layout>
						<CourseSectionForm />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/course_sections/:courseSectionId/stats"
				element={
					<Layout>
						<CourseSectionStats />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/course_sections/:courseSectionId/course_section_steps/new"
				element={
					<Layout>
						<CourseSectionStepForm />
					</Layout>
				}
			/>
			<Route
				path="courses/:courseId/course_sections/:courseSectionId/course_section_steps/:courseSectionStepId"
				element={
					<Layout>
						<CourseSectionStepForm />
					</Layout>
				}
			/>
			<Route
				path="practices"
				element={
					<Layout>
						<PracticesList />
					</Layout>
				}
			/>
			<Route
				path="practices/new"
				element={
					<Layout>
						<PracticeForm />
					</Layout>
				}
			/>
			<Route
				path="practices/:practiceId"
				element={
					<Layout>
						<PracticeForm />
					</Layout>
				}
			/>
			<Route
				path="practices/:practiceId/stats"
				element={
					<Layout>
						<PracticeStats />
					</Layout>
				}
			/>
			<Route
				path="practices/:practiceId/practice_steps/new"
				element={
					<Layout>
						<PracticeStepForm />
					</Layout>
				}
			/>
			<Route
				path="practices/:practiceId/practice_steps/:practiceStepId"
				element={
					<Layout>
						<PracticeStepForm />
					</Layout>
				}
			/>
			<Route
				path="prompts"
				element={
					<Layout>
						<PromptsList />
					</Layout>
				}
			/>
			<Route
				path="prompts/new"
				element={
					<Layout>
						<PromptForm />
					</Layout>
				}
			/>
			<Route
				path="prompts/:promptId"
				element={
					<Layout>
						<PromptForm />
					</Layout>
				}
			/>
			<Route
				path="orgs"
				element={
					<Layout>
						<OrgsList />
					</Layout>
				}
			/>
			<Route
				path="orgs/new"
				element={
					<Layout>
						<OrgForm />
					</Layout>
				}
			/>
			<Route
				path="orgs/:orgId"
				element={
					<Layout>
						<OrgForm />
					</Layout>
				}
			/>
			<Route
				path="features"
				element={
					<Layout>
						<FeaturesList />
					</Layout>
				}
			/>
			<Route
				path="features/new"
				element={
					<Layout>
						<FeatureForm />
					</Layout>
				}
			/>
			<Route
				path="features/:featureId"
				element={
					<Layout>
						<FeatureForm />
					</Layout>
				}
			/>
			<Route
				path="check_in_period_images"
				element={
					<Layout>
						<CheckInPeriodImagesList />
					</Layout>
				}
			/>
			<Route
				path="check_in_period_images/new"
				element={
					<Layout>
						<CheckInPeriodImageForm />
					</Layout>
				}
			/>
			<Route
				path="check_in_period_images/:checkInPeriodImageId"
				element={
					<Layout>
						<CheckInPeriodImageForm />
					</Layout>
				}
			/>
			<Route
				path="authors"
				element={
					<Layout>
						<AuthorsList />
					</Layout>
				}
			/>
			<Route
				path="authors/new"
				element={
					<Layout>
						<AuthorForm />
					</Layout>
				}
			/>
			<Route
				path="authors/:authorId"
				element={
					<Layout>
						<AuthorForm />
					</Layout>
				}
			/>
			<Route
				path="podcasts"
				element={
					<Layout>
						<PodcastsList />
					</Layout>
				}
			/>
			<Route
				path="podcasts/:podcastId"
				element={
					<Layout>
						<PodcastsForm />
					</Layout>
				}
			/>
			<Route
				path="podcasts/new"
				element={
					<Layout>
						<PodcastsForm />
					</Layout>
				}
			/>
			<Route
				path="podcasts/:podcastId/episodes"
				element={
					<Layout>
						<PodcastEpisodesList />
					</Layout>
				}
			/>
			<Route
				path="podcasts/:podcastId/episodes/new"
				element={
					<Layout>
						<PodcastEpisodesForm />
					</Layout>
				}
			/>
			<Route
				path="podcasts/:podcastId/episodes/:podcastEpisodeId"
				element={
					<Layout>
						<PodcastEpisodesForm />
					</Layout>
				}
			/>
			<Route
				path="groups"
				element={
					<Layout>
						<GroupsList />
					</Layout>
				}
			/>
			<Route
				path="groups/:groupId"
				element={
					<Layout>
						<GroupForm />
					</Layout>
				}
			/>
			<Route
				path="groups/:groupId/update_org"
				element={
					<Layout>
						<GroupOrgForm />
					</Layout>
				}
			/>
			<Route
				path="groups/new"
				element={
					<Layout>
						<GroupForm />
					</Layout>
				}
			/>
			<Route
				path="settings"
				element={
					<Layout>
						<OrgForm />
					</Layout>
				}
			/>
			<Route
				path="goals"
				element={
					<Layout>
						<GoalsList />
					</Layout>
				}
			/>
			<Route
				path="goals/new"
				element={
					<Layout>
						<GoalForm />
					</Layout>
				}
			/>

			<Route
				path="goals/:goalId"
				element={
					<Layout>
						<GoalForm />
					</Layout>
				}
			/>
			<Route
				path="channels"
				element={
					<Layout>
						<ChannelsList />
					</Layout>
				}
			/>
			<Route
				path="channels/new"
				element={
					<Layout>
						<ChannelForm />
					</Layout>
				}
			/>
			<Route
				path="channels/:channelId"
				element={
					<Layout>
						<ChannelForm />
					</Layout>
				}
			/>
			<Route
				path="challenges"
				element={
					<Layout>
						<ChallengesList />
					</Layout>
				}
			/>
			<Route
				path="challenges/new"
				element={
					<Layout>
						<ChallengeForm />
					</Layout>
				}
			/>
			<Route
				path="challenges/:challengeId"
				element={
					<Layout>
						<ChallengeForm />
					</Layout>
				}
			/>
			<Route
				path="resources"
				element={
					<Layout>
						<LeaderResourcesList />
					</Layout>
				}
			/>
			<Route
				path="resources/:leaderResourceId/stats"
				element={
					<Layout>
						<LeaderResourceStats />
					</Layout>
				}
			/>
			<Route
				path="resources/new"
				element={
					<Layout>
						<LeaderResourceForm />
					</Layout>
				}
			/>
			<Route
				path="resources/:leaderResourceId"
				element={
					<Layout>
						<LeaderResourceForm />
					</Layout>
				}
			/>
			<Route
				path="resource_folders/:leaderResourceFolderId/resources/new"
				element={
					<Layout>
						<LeaderResourceForm />
					</Layout>
				}
			/>
			<Route
				path="resource_folders/new"
				element={
					<Layout>
						<LeaderResourceFolderForm />
					</Layout>
				}
			/>
			<Route
				path="resource_folders/:leaderResourceFolderId"
				element={
					<Layout>
						<LeaderResourceFolderForm />
					</Layout>
				}
			/>
			<Route
				path="invitations"
				element={
					<Layout>
						<InvitationsList />
					</Layout>
				}
			/>
			<Route
				path="invitations/new"
				element={
					<Layout>
						<InvitationForm />
					</Layout>
				}
			/>
			<Route
				path="assessments"
				element={
					<Layout>
						<AssessmentsList />
					</Layout>
				}
			/>
			<Route
				path="assessments/new"
				element={
					<Layout>
						<AssessmentForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId"
				element={
					<Layout>
						<AssessmentForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_periods"
				element={
					<Layout>
						<AssessmentPeriodsList />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_periods/:assessmentPeriodId"
				element={
					<Layout>
						<AssessmentPeriodResults />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_questions/new"
				element={
					<Layout>
						<AssessmentQuestionForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_questions/:assessmentQuestionId"
				element={
					<Layout>
						<AssessmentQuestionForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_questions/:assessmentQuestionId/assessment_question_answers/new"
				element={
					<Layout>
						<AssessmentQuestionAnswerForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_questions/:assessmentQuestionId/assessment_question_answers/:assessmentQuestionAnswerId"
				element={
					<Layout>
						<AssessmentQuestionAnswerForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_action_links/new"
				element={
					<Layout>
						<AssessmentActionLinkForm />
					</Layout>
				}
			/>
			<Route
				path="assessments/:assessmentId/assessment_action_links/:assessmentActionLinkId"
				element={
					<Layout>
						<AssessmentActionLinkForm />
					</Layout>
				}
			/>
			<Route
				path="links"
				element={
					<Layout>
						<CustomFieldsList />
					</Layout>
				}
			/>
			<Route
				path="links/new"
				element={
					<Layout>
						<CustomFieldForm />
					</Layout>
				}
			/>
			<Route
				path="links/:customFieldId"
				element={
					<Layout>
						<CustomFieldForm />
					</Layout>
				}
			/>
			<Route
				path="users"
				element={
					<Layout>
						<UsersList />
					</Layout>
				}
			/>
			<Route
				path="users/:userId"
				element={
					<Layout>
						<UserForm />
					</Layout>
				}
			/>
			<Route
				path="collections"
				element={
					<Layout>
						<CollectionsList />
					</Layout>
				}
			/>
			<Route
				path="collections/new"
				element={
					<Layout>
						<CollectionForm />
					</Layout>
				}
			/>
			<Route
				path="collections/:collectionId"
				element={
					<Layout>
						<CollectionForm />
					</Layout>
				}
			/>
			<Route
				path="collections/:collectionId/collection_items/new"
				element={
					<Layout>
						<CollectionItemForm />
					</Layout>
				}
			/>
			<Route
				path="billing"
				element={
					<Layout>
						<BillingPage />
					</Layout>
				}
			/>
			<Route
				path="integrations"
				element={
					<Layout>
						<IntegrationsIndex />
					</Layout>
				}
			/>
			<Route
				path="oauth/planning_center"
				element={
					<Layout>
						<PlanningCenterCallback />
					</Layout>
				}
			/>
			<Route
				path="habits"
				element={
					<Layout>
						<OrgHabitsList />
					</Layout>
				}
			/>
			<Route
				path="habits/:orgHabitId/stats"
				element={
					<Layout>
						<OrgHabitStats />
					</Layout>
				}
			/>
			<Route
				path="habits/new"
				element={
					<Layout>
						<OrgHabitForm />
					</Layout>
				}
			/>
			<Route
				path="habits/:orgHabitId"
				element={
					<Layout>
						<OrgHabitForm />
					</Layout>
				}
			/>
			<Route
				path="stock_images"
				element={
					<Layout>
						<StockImagesList />
					</Layout>
				}
			/>
			<Route
				path="stock_images/new"
				element={
					<Layout>
						<StockImageForm />
					</Layout>
				}
			/>
			<Route
				path="stock_images/:stockImageId"
				element={
					<Layout>
						<StockImageForm />
					</Layout>
				}
			/>
			<Route
				path="imports"
				element={
					<Layout>
						<ImportsList />
					</Layout>
				}
			/>
			<Route
				path="imports/new"
				element={
					<Layout>
						<ImportForm />
					</Layout>
				}
			/>
			<Route
				path="imports/:importRecordId"
				element={
					<Layout>
						<ImportForm />
					</Layout>
				}
			/>
			<Route
				path="exports"
				element={
					<Layout>
						<ExportsList />
					</Layout>
				}
			/>
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	);
};

export default observer(App);
