import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { statusPill } from '../../utils/pillHelper';
import moment from 'moment';
import { statusLabels } from '../../utils/humanReadableLabels';

function CollectionsList() {
	const [collections, setCollections] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchCollections();
		}
	}, [orgsStore.currentOrg]);

	const fetchCollections = async () => {
		const collectionsResponse = await tetherApi.getCollections();

		setCollections(collectionsResponse.data);
	};

	const reduceCollectionIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var collection = collections[parseInt(currentIndex)];

		await tetherApi.updateCollection(collection.id, {
			index: collection.index - 1,
		});

		fetchCollections();
	};

	const increaseCollectionIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var collection = collections[parseInt(currentIndex)];

		await tetherApi.updateCollection(collection.id, {
			index: collection.index + 1,
		});

		fetchCollections();
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Collections</h1>
					<p className="text-sm text-gray-500">Organize and group your content.</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://intercom.help/withtether/en/articles/9535099-about-collections"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link>
					<Link
						to="/collections/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Collection
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{collections.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No collections to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No collections are available.
										</p>
										<Link
											to="/collections/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Collection
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Status
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Featured
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Last Updated
											</th>
											{/* <th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th> */}
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{collections.map((collection, i) => (
											<tr key={collection.id} className={i % 2 === 0 ? undefined : 'bg-white'}>
												<td className="py-whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceCollectionIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === collections.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === collections.length - 1
																? null
																: increaseCollectionIndex
														}
													/>

													{collection.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													<span
														className={
															'inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium ' +
															statusPill(collection.status)
														}
													>
														{statusLabels[collection.status] || 'No Status'}
													</span>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
													{collection.isFeatured ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(collection.updatedAt).format('LL')}
												</td>
												<td className="flex justify-end relative whitespace-nowrap py-3 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/collections/${collection.id}`}
														className="inline-flex rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:mt-4 lg:mt-0"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(CollectionsList);
