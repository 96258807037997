import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import { CloudUpload } from 'lucide-react';

function PodcastsForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			episode_type: 'Full',
		},
	});

	const { podcastId, podcastEpisodeId } = useParams();
	const [podcast, setPodcast] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const episodeTypes = ['Full', 'Trailer', 'Bonus']; // Assuming these are the types

	let navigate = useNavigate();

	useEffect(() => {
		if (podcastEpisodeId) {
			fetchPodcast(podcastEpisodeId);
		} else {
			reset();
		}

		setSuccessMessage(podcastEpisodeId ? 'Podcast episode updated!' : 'Podcast episode created!');
	}, [podcastEpisodeId]);

	const fetchPodcast = async (podcastEpisodeId) => {
		try {
			const podcastResponse = await tetherApi.getPodcastEpisode(podcastId, podcastEpisodeId);

			if (podcastResponse && podcastResponse.data) {
				setPodcast(podcastResponse.data);
				reset(podcastResponse.data);
			} else {
				throw new Error('Podcast episode data is not available');
			}
		} catch (error) {
			console.error('Failed to fetch podcast episode:', error);
			alertService.error('Failed to load podcast episode. Please try again later.');
		}
	};

	const onSubmit = async (data) => {
		setIsLoading(true);
		const result = await submitPodcast(data);

		if (result) {
			alertService.success(successMessage);
			navigate(`/podcasts/${podcastId}/episodes`);
		}
		setIsLoading(false);
	};

	const submitPodcast = async (data) => {
		const formData = new FormData(document.getElementById('podcast-form'));

		if (data.id) {
			return await tetherApi.updatePodcastEpisode(podcastId, data.id, formData);
		} else {
			return await tetherApi.createPodcastEpisode(podcastId, formData);
		}
	};

	const deletePodcast = async () => {
		const result = await tetherApi.deletePodcastEpisode(podcastId, podcastEpisodeId);

		if (result) {
			alertService.success('Podcast episode deleted');

			navigate('/podcasts');
		}
	};

	return (
		<form className="p-12 space-y-8" id="podcast-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 sm:space-y-5">
				<div>
					<h3 className="mb-6 text-3xl font-semibold text-gray-900">
						{podcastEpisodeId ? 'Edit' : 'New'} Episode
					</h3>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the title, description, and other details.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="audio_file"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Audio File*
								</label>
								<div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
									<div className="text-center">
										<CloudUpload aria-hidden="true" className="mx-auto size-16 text-gray-300" />
										<div className="mt-4 flex text-sm/6 text-gray-600">
											<label
												htmlFor="audio_file"
												className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
											>
												<span>Upload a file</span>
												<input
													{...register('audio_file', {
														required: 'Audio file is required',
													})}
													id="audio_file"
													name="audio_file"
													type="file"
													accept=".mp3,.wav"
													className="sr-only"
												/>
											</label>
											<p className="pl-1">or drag and drop</p>
										</div>
										<p className="text-xs/5 text-gray-600">MP3, WAV up to 10MB</p>
									</div>
								</div>
								<p
									className={errors.audio_file?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="audio-file-error"
								>
									{errors.audio_file?.message}
								</p>
							</div>
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Title*
								</label>
								<div className="mt-2">
									<input
										{...register('title', {
											required: 'Title is required',
											maxLength: 'Title must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="description"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Description*
								</label>
								<div className="mt-2">
									<textarea
										{...register('description', {
											required: 'Description is required',
										})}
										rows={5}
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
									/>
									<p
										className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="description-error"
									>
										{errors.description?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full grid grid-cols-1 md:grid-cols-3 gap-6">
								<div>
									<label
										htmlFor="season"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Season*
									</label>
									<div className="mt-2">
										<input
											{...register('season', {
												required: 'Season is required',
												valueAsNumber: true,
											})}
											type="number"
											min="0"
											className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
										/>
										<p
											className={errors.season?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
											id="season-error"
										>
											{errors.season?.message}
										</p>
									</div>
								</div>
								<div>
									<label
										htmlFor="episode_number"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Episode Number*
									</label>
									<div className="mt-2">
										<input
											{...register('episode_number', {
												required: 'Episode number is required',
												valueAsNumber: true,
											})}
											type="number"
											min="0"
											className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
										/>
										<p
											className={
												errors.episode_number?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
											}
											id="episode-number-error"
										>
											{errors.episode_number?.message}
										</p>
									</div>
								</div>
								<div>
									<label
										htmlFor="episode_type"
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										Episode Type*
									</label>
									<div className="mt-2">
										<select
											{...register('episode_type', {
												required: 'Episode type is required',
											})}
											className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
										>
											<option value="">Select a type</option>
											{episodeTypes.map((type, index) => (
												<option key={index} value={type}>
													{type}
												</option>
											))}
										</select>
										<p
											className={
												errors.episode_type?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
											}
											id="episode-type-error"
										>
											{errors.episode_type?.message}
										</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="artist" className="block text-sm font-medium leading-6 text-gray-900">
									Artist*
								</label>
								<div className="mt-2">
									<input
										{...register('artist', {
											required: 'Artist is required',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.artist?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="artist-error"
									>
										{errors.artist?.message}
									</p>
								</div>
							</div>

							<div className="col-span-full">
								<label htmlFor="explicit" className="block text-sm font-medium leading-6 text-gray-900">
									Explicit Content
								</label>
								<div className="mt-2">
									<input
										{...register('explicit')}
										type="checkbox"
										className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
									/>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
									Website
								</label>
								<div className="mt-2">
									<input
										{...register('website', {
											pattern: {
												value: /^https?:\/\/.+/,
												message: 'Must be a valid URL',
											},
										})}
										type="url"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.website?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="website-error"
									>
										{errors.website?.message}
									</p>
								</div>
							</div>

							<div className="col-span-full">
								<label htmlFor="tags" className="block text-sm font-medium leading-6 text-gray-900">
									Tags
								</label>
								<div className="mt-2">
									<input
										{...register('tags', {
											pattern: {
												value: /^[a-zA-Z0-9, ]+$/,
												message: 'Must be a list of comma separated words',
											},
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md"
									/>
									<p
										className={errors.keywords?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="keywords-error"
									>
										{errors.keywords?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Images</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">Edit the podcast image.</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Image
								</label>
								<div className="mt-2">
									<input
										{...register('image')}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50 file:border file:border-gray-300 file:text-gray-700 file:mr-4 max-w-lg block w-full border border-solid border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm"
									/>
									<p className="mt-2 text-sm text-gray-500">Accepts .png or .jpg</p>
									<p
										className={errors.image?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="image-error"
									>
										{errors.image?.message}
									</p>

									<div className={podcast?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={podcast?.imageUrl}
											alt=""
											className={podcast?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							podcastEpisodeId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/podcasts"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting || isLoading}
						className={`disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm ${
							isLoading ? 'bg-gray-500' : 'bg-neutral-900'
						}`}
					>
						{isLoading ? 'Uploading...' : 'Save'}
					</button>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Podcast"
				body="This will delete the Podcast and cannot be undone. Are you sure?"
				onConfirm={deletePodcast}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(PodcastsForm);
