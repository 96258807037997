import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import orgsStore from '../../stores/OrgsStore';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import { Metric, Text, Grid, Card, Col } from '@tremor/react';

function OrgHabitStats() {
	const { orgHabitId } = useParams();
	const [orgHabit, setOrgHabit] = useState(null);
	const [visits, setVisits] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			if (orgHabitId) {
				await fetchOrgHabit(orgHabitId);
				await fetchOrgHabitVisits(orgHabitId);
			}
		};
		fetchData();
	}, [orgHabitId]);

	const fetchOrgHabit = async (orgHabitId) => {
		const orgHabitResponse = await tetherApi.getOrgHabit(orgHabitId);

		setOrgHabit(orgHabitResponse.data);
	};

	const fetchOrgHabitVisits = async (orgHabitId) => {
		const viewsResponse = await tetherApi.getAhoyMetricsCount({
			org_id: orgsStore.currentOrg.id,
			org_habit_id: orgHabitId,
		});
		setVisits(viewsResponse ?? 0);
	};

	if (!orgHabit) {
		return <div></div>;
	}

	return (
		<div className="px-8">
			<Grid numItemsLg={8} className="gap-6 mt-6">
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Visits</Text>
								<Metric className="font-medium">{visits}</Metric>
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
		</div>
	);
}

export default observer(OrgHabitStats);
