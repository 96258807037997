import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import Confirm from '../Confirm';
import { humanize } from '../../utils/stringUtils';
import Pagination from '../Pagination';
import UserSearch from '../users/UserSearch';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { Select, SelectItem } from '@tremor/react';

function GroupForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({});

	const { groupId } = useParams();
	const [group, setGroup] = useState(null);

	const [usersPage, setUsersPage] = useState(1);
	const [usersPerPage] = useState(10);
	const [usersTotal, setUsersTotal] = useState(0);
	const [usersNumPages, setUsersNumPages] = useState(1);
	const [usersStartIndex, setUsersStartIndex] = useState(0);
	const [usersEndIndex, setUsersEndIndex] = useState(0);

	const [invitationsPage, setInvitationsPage] = useState(1);
	const [invitationsPerPage] = useState(10);
	const [invitationsTotal, setInvitationsTotal] = useState(0);
	const [invitationsNumPages, setInvitationsNumPages] = useState(1);
	const [invitationsStartIndex, setInvitationsStartIndex] = useState(0);
	const [invitationsEndIndex, setInvitationsEndIndex] = useState(0);

	const [users, setUsers] = useState([]);
	const [invitations, setInvitations] = useState([]);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [adminConfirm, setAdminConfirm] = useState(false);

	const [revealedEmails, setRevealedEmails] = useState([]);
	const [revealedPhones, setRevealedPhones] = useState([]);

	const [userSearchOpen, setUserSearchOpen] = useState(false);

	const [userToChangeRole, setUserToChangeRole] = useState(null);

	const [orgPathways, setOrgPathways] = useState([]);
	const [addingPlan, setAddingPlan] = useState(false);
	const [selectedPlanId, setSelectedPlanId] = useState(null);
	const [planSelectedStartDate, setPlanSelectedStartDate] = useState(moment().format('YYYY-MM-DD'));
	const [addPlanLoading, setAddPlanLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState('Details');

	const [orgCourses, setOrgCourses] = useState([]);
	const [addingCourse, setAddingCourse] = useState(false);
	const [selectedCourseId, setSelectedCourseId] = useState(null);
	const [courseSelectedStartDate, setCourseSelectedStartDate] = useState(moment().format('YYYY-MM-DD'));
	const [addCourseLoading, setAddCourseLoading] = useState(false);

	const [deleteCourseConfirm, setDeleteCourseConfirm] = useState(false);
	const [courseToDelete, setCourseToDelete] = useState(null);
	const [deletePlanConfirm, setDeletePlanConfirm] = useState(false);
	const [planToDelete, setPlanToDelete] = useState(null);

	let navigate = useNavigate();

	useEffect(() => {
		if (groupId && !group && orgsStore.orgs.length) {
			fetchGroup(groupId);
		}

		if (group) {
			fetchUsers(groupId);
		}

		if (group) {
			fetchInvitations(groupId);
		}

		if (!orgPathways.length) {
			fetchPathways();
		}

		if (!orgCourses.length) {
			fetchCourses();
		}

		setSuccessMessage(groupId ? 'Group updated!' : 'Group created!');
	}, [group, invitationsPage, usersPage, orgsStore.currentOrg, deleteConfirm, userSearchOpen]);

	const fetchPathways = async () => {
		let allPathways = [];
		const { data: pathwaysPage, total } = await tetherApi.getPathways(1, 100);
		allPathways = allPathways.concat(pathwaysPage);
		if (total > 100) {
			const { data: pathwaysPage2 } = await tetherApi.getPathways(2, 100);
			allPathways = allPathways.concat(pathwaysPage2);
		}
		setOrgPathways(allPathways);
	};

	const fetchCourses = async () => {
		let allCourses = [];
		const { data: coursesPage, total } = await tetherApi.getCourses(1, 100);
		allCourses = allCourses.concat(coursesPage);
		if (total > 100) {
			const { data: coursesPage2 } = await tetherApi.getCourses(2, 100);
			allCourses = allCourses.concat(coursesPage2);
		}
		setOrgCourses(allCourses);
	};

	const fetchGroup = async (groupId) => {
		const groupResponse = await tetherApi.getGroup(groupId);

		setGroup(groupResponse.data);

		reset(groupResponse.data);
	};

	const removeUser = async (userId) => {
		const result = await tetherApi.removeUserFromGroup(groupId, userId);

		if (result) {
			alertService.success('User removed');

			fetchUsers(groupId);
		}
	};

	const addUser = async (event) => {
		event.preventDefault();

		const userId = event.target.dataset.userId;

		const result = await tetherApi.addUserToGroup(groupId, userId);

		if (result) {
			setUserSearchOpen(false);

			alertService.success('User added');

			fetchUsers(groupId);
		}
	};

	const handleUserAdd = (event) => {
		event.preventDefault();

		setUserSearchOpen(true);
	};

	const fetchUsers = async (groupId) => {
		try {
			const usersResponse = await tetherApi.getUsers(usersPage, usersPerPage, {
				status: 'pending',
				groupId: groupId,
				orgId: group?.orgId,
			});

			if (!usersResponse) {
				return;
			}

			setUsers(usersResponse.data || []);

			const usersTotalCount = usersResponse.total || 0;
			const usersPagesCount = Math.ceil(usersTotalCount / usersPerPage);

			setUsersTotal(usersTotalCount);
			setUsersNumPages(usersPagesCount);
			setUsersStartIndex(usersTotalCount > 0 ? usersPerPage * (usersPage - 1) + 1 : 0);
			setUsersEndIndex(usersPerPage * (usersPage - 1) + (usersResponse.data?.length || 0));
		} catch (error) {
			setUsers([]);
			setUsersTotal(0);
			setUsersNumPages(1);
			setUsersStartIndex(0);
			setUsersEndIndex(0);
		}
	};

	const fetchInvitations = async (groupId) => {
		try {
			const invitationsResponse = await tetherApi.getInvitations(invitationsPage, invitationsPerPage, {
				status: 'pending',
				groupId: groupId,
				orgId: group?.orgId,
			});

			if (!invitationsResponse) {
				return;
			}

			setInvitations(invitationsResponse.data || []);

			const invitationsTotalCount = invitationsResponse.total || 0;
			const invitationsPagesCount = Math.ceil(invitationsTotalCount / invitationsPerPage);

			setInvitationsTotal(invitationsTotalCount);
			setInvitationsNumPages(invitationsPagesCount);
			setInvitationsStartIndex(invitationsTotalCount > 0 ? invitationsPerPage * (invitationsPage - 1) + 1 : 0);
			setInvitationsEndIndex(
				invitationsPerPage * (invitationsPage - 1) + (invitationsResponse.data?.length || 0),
			);
		} catch (error) {
			setInvitations([]);
			setInvitationsTotal(0);
			setInvitationsNumPages(1);
			setInvitationsStartIndex(0);
			setInvitationsEndIndex(0);
		}
	};

	const onSubmit = async (data) => {
		const result = await submitGroup(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/groups');
		}
	};

	const submitGroup = async (data) => {
		const formData = new FormData(document.getElementById('group-form'));
		if (data['image'][0]) {
			formData.append('image', data['image'][0]);
		}

		if (data.id) {
			return await tetherApi.updateGroup(data.id, formData);
		} else {
			return await tetherApi.createGroup(formData);
		}
	};

	const deleteGroup = async () => {
		const result = await tetherApi.deleteGroup(groupId);

		if (result) {
			alertService.success('Group deleted');

			navigate('/groups');
		}
	};

	const changeUserRole = async () => {
		if (userToChangeRole) {
			await tetherApi.updateUserRoleInGroup(group.id, userToChangeRole.id, userToChangeRole.isLeader);
			const usersClone = [...users];
			const userIndex = usersClone.findIndex((u) => u.id === userToChangeRole.id);
			if (userIndex >= 0) {
				usersClone[userIndex].isLeader = userToChangeRole.isLeader;
				setUsers(usersClone);
			}
			setUserToChangeRole(null);
			setAdminConfirm(false);
		}
	};

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	const renderDetails = () => {
		return (
			<>
				<div className="mt-10">
					<h3 className="text-2xl leading-6 font-semibold text-gray-900">{group?.name}</h3>
				</div>
				<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-12">
					<div>
						<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
						<p className="mt-1 text-sm leading-6 text-gray-600">
							Edit the space name, description, and image.
						</p>
					</div>
					<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
						<div className="col-span-full">
							<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
								Name*
							</label>
							<div className="mt-2">
								<input
									{...register('name', {
										required: 'Name is required',
										maxLength: 'Name must be 255 characters or less',
									})}
									type="text"
									className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.name?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="name-error"
								>
									{errors.name?.message}
								</p>
							</div>
						</div>
						<div className="col-span-full">
							<label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
								Description
							</label>
							<div className="mt-2">
								<input
									{...register('description', {
										maxLength: 'description must be 255 characters or less',
									})}
									type="text"
									placeholder=""
									className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p
									className={errors.description?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="url-string-error"
								>
									{errors.description?.message}
								</p>
							</div>
						</div>
						<div className="col-span-full">
							<label htmlFor="meetingUrl" className="block text-sm font-medium leading-6 text-gray-900">
								Video Link
							</label>
							<div className="mt-2">
								<input
									{...register('meetingUrl', {
										maxLength: 'Meeting URL must be 255 characters or less',
									})}
									type="text"
									placeholder="https://"
									className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
								/>
								<p className="mt-2 text-sm text-gray-500  font-regular">
									Use this to display a ZOOM or Google Meet link.
								</p>
								<p
									className={errors.meetingUrl?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
									id="url-string-error"
								>
									{errors.meetingUrl?.message}
								</p>
							</div>
						</div>
						<div className="col-span-full">
							<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
								Image
							</label>
							<div className="mt-2">
								<input
									{...register('image')}
									type="file"
									name="image"
									id="image"
									accept=".png,.jpg,.jpeg"
									className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm  font-regular"
								/>
								<p className="mt-2 text-sm text-gray-500  font-regular">Accepts .png or .jpg</p>

								<div className={group?.image ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
									<img src={group?.image} alt="" className={group?.image ? '' : 'hidden'} />
								</div>
							</div>
						</div>
						{group?.publicToken && (
							<div className="col-span-full">
								<label
									htmlFor="lastActive"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Invite Code
								</label>
								<div className="block w-full mt-2 bg-gray-100 p-3 rounded-lg">{group?.publicToken}</div>
								<p className="mt-2 text-sm text-gray-500  font-regular">
									You can use this code to join the space
								</p>
							</div>
						)}
					</div>
				</div>
				<div className="pt-5">
					<div className="flex justify-end">
						<button
							type="button"
							onClick={() => setDeleteConfirm(true)}
							className="mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
						>
							Delete
						</button>
						<Link
							to="/groups"
							type="button"
							className={
								isSubmitting
									? 'hidden'
									: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
							}
						>
							Cancel
						</Link>
						<button
							className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							type="submit"
							disabled={!isDirty || isSubmitting}
						>
							Save
						</button>
					</div>
				</div>
			</>
		);
	};

	const renderContentTab = () => {
		const schedules = [
			...(group?.plans?.map((plan) => ({ ...plan, type: 'Plan' })) || []),
			...(group?.courses?.map((course) => ({ ...course, type: 'Course' })) || []),
		].sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt));

		return (
			<div className="mt-5">
				<div className="">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl text-gray-900 font-semibold">Content</h1>
						</div>
						<div className="flex space-x-4">
							{addingPlan || addingCourse ? (
								<div className="mt-6 flex flex-row items-end">
									{addingPlan ? (
										<>
											<div className="mt-1 mr-4 sm:mt-0 sm:col-span-2">
												<Select
													className="w-[300px]"
													value={selectedPlanId || ''}
													onValueChange={(value) => {
														setSelectedPlanId(value);
													}}
													placeholder="Select a Plan..."
												>
													{orgPathways?.map((pathway) => (
														<SelectItem
															key={`practice-${pathway.id}`}
															value={pathway.id}
															className="w-full"
														>
															{pathway.name}
														</SelectItem>
													))}
												</Select>
											</div>
											<div className="relative">
												<ReactDatePicker
													dateFormat={'MMMM d, yyyy'}
													selected={
														planSelectedStartDate
															? moment(planSelectedStartDate).toDate()
															: null
													}
													onChange={(date) => {
														setPlanSelectedStartDate(moment(date).format('YYYY-MM-DD'));
													}}
													calendarClassName="datepicker-calendar"
													type="text"
													autocomplete="off"
													className="w-[200px] shadow-sm rounded-tremor-default text-tremor-default ring-1 ring-inset ring-tremor-ring bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content dark:text-dark-tremor-content border-0 py-2 px-3 focus:ring-2 focus:ring-tremor-brand dark:focus:ring-dark-tremor-brand"
												/>
											</div>
											<button
												type="button"
												disabled={!selectedPlanId || !group}
												className="disabled:opacity-25 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 ml-4"
												onClick={async () => {
													setAddPlanLoading(true);
													await tetherApi.addPathwaySchedule(selectedPlanId, {
														groupId: group.id,
														startDate: planSelectedStartDate,
													});
													setAddPlanLoading(false);
													setAddingPlan(false);
													setPlanSelectedStartDate(moment().format('YYYY-MM-DD'));
													fetchGroup(groupId);
												}}
											>
												{addPlanLoading ? 'Loading...' : 'Schedule Plan'}
											</button>
										</>
									) : (
										<>
											<div className="mt-1 mr-4 sm:mt-0 sm:col-span-2">
												<Select
													className="w-[300px]"
													value={selectedCourseId || ''}
													onValueChange={(value) => {
														setSelectedCourseId(value);
													}}
													placeholder="Select a Course..."
												>
													{orgCourses?.map((course) => (
														<SelectItem
															key={`course-${course.id}`}
															value={course.id}
															className="w-full"
														>
															{course.name}
														</SelectItem>
													))}
												</Select>
											</div>
											<ReactDatePicker
												dateFormat={'MMMM d, yyyy'}
												selected={
													courseSelectedStartDate
														? moment(courseSelectedStartDate).toDate()
														: null
												}
												onChange={(date) => {
													setCourseSelectedStartDate(moment(date).format('YYYY-MM-DD'));
												}}
												calendarClassName="datepicker-calendar"
												type="text"
												autocomplete="off"
												className="w-[200px] shadow-sm rounded-tremor-default text-tremor-default ring-1 ring-inset ring-tremor-ring bg-tremor-background dark:bg-dark-tremor-background hover:bg-tremor-background-muted dark:hover:bg-dark-tremor-background-muted text-tremor-content dark:text-dark-tremor-content border-0 py-2 px-3 focus:ring-2 focus:ring-tremor-brand dark:focus:ring-dark-tremor-brand"
											/>
											<button
												type="button"
												disabled={!selectedCourseId || !group}
												className="disabled:opacity-25 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 ml-4"
												onClick={async () => {
													setAddCourseLoading(true);
													await tetherApi.addCourseSchedule(selectedCourseId, {
														groupId: group.id,
														startedAt: courseSelectedStartDate,
													});
													setAddCourseLoading(false);
													setAddingCourse(false);
													setCourseSelectedStartDate(moment().format('YYYY-MM-DD'));
													fetchGroup(groupId);
												}}
											>
												{addCourseLoading ? 'Loading...' : 'Schedule Course'}
											</button>
										</>
									)}
								</div>
							) : (
								<div className="mt-6 flex space-x-4">
									<button
										type="button"
										className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
										onClick={() => setAddingPlan(true)}
									>
										Schedule Plan
									</button>
									<button
										type="button"
										className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
										onClick={() => setAddingCourse(true)}
									>
										Schedule Course
									</button>
								</div>
							)}
						</div>
					</div>
				</div>

				{schedules.length > 0 ? (
					<div className="">
						<div className="mt-8 flow-root">
							<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-white">
											<tr>
												<th
													scope="col"
													className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
												>
													Name
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Type
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Start Date
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Status
												</th>
												{/* <th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Actions
												</th> */}
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{schedules.map((schedule, idx) => (
												<tr
													key={`${schedule.type}-${schedule.id}`}
													className={idx % 2 === 0 ? undefined : 'bg-white'}
												>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
														{schedule.name}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{schedule.type}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{moment(schedule.startedAt).format('MMM D, YYYY')}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{schedule.completedAt ? 'Completed' : 'Active'}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
														{schedule.type === 'Course' && !schedule.completedAt && (
															<>
																<button
																	type="button"
																	onClick={() => {
																		setCourseToDelete(schedule);
																		setDeleteCourseConfirm(true);
																	}}
																	className="text-white bg-red-600 hover:bg-red-700 px-3 py-2 rounded-md text-sm font-medium"
																>
																	Remove Course
																</button>
																<Confirm
																	isOpen={
																		deleteCourseConfirm &&
																		courseToDelete?.id === schedule.id
																	}
																	title="Remove Course"
																	body="This will remove this course from the group's schedule. Are you sure?"
																	onConfirm={async () => {
																		await tetherApi.deleteGroupCourseSchedule(
																			schedule.course.id,
																			schedule.id,
																		);
																		setDeleteCourseConfirm(false);
																		setCourseToDelete(null);
																		fetchGroup(groupId);
																	}}
																	onCancel={() => {
																		setDeleteCourseConfirm(false);
																		setCourseToDelete(null);
																	}}
																/>
															</>
														)}
														{schedule.type === 'Plan' && !schedule.completedAt && (
															<>
																<button
																	type="button"
																	onClick={() => {
																		setPlanToDelete(schedule);
																		setDeletePlanConfirm(true);
																	}}
																	className="text-white bg-red-600 hover:bg-red-700 px-3 py-2 rounded-md text-sm font-medium"
																>
																	Remove Plan
																</button>
																<Confirm
																	isOpen={
																		deletePlanConfirm &&
																		planToDelete?.id === schedule.id
																	}
																	title="Remove Plan"
																	body="This will remove this plan from the group's schedule. Are you sure?"
																	onConfirm={async () => {
																		await tetherApi.deleteGroupPathwaySchedule(
																			schedule.pathwayId,
																			schedule.scheduleId,
																			group.id,
																		);
																		setDeletePlanConfirm(false);
																		setPlanToDelete(null);
																		fetchGroup(groupId);
																	}}
																	onCancel={() => {
																		setDeletePlanConfirm(false);
																		setPlanToDelete(null);
																	}}
																/>
															</>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="text-center mt-8 bg-white rounded-lg px-4 py-16">
						<div className="mx-auto max-w-lg">
							<h3 className="mt-2 text-lg font-semibold text-gray-900">No content scheduled</h3>
							<p className="mt-1 text-sm text-gray-500">
								Get started by scheduling a plan or course for your space.
							</p>
							<div className="mt-6">
								<button
									type="button"
									className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
									onClick={() => setAddingPlan(true)}
								>
									Schedule Plan
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	};

	const renderUsersTab = () => {
		return (
			<>
				<div className={groupId ? '' : 'hidden'}>
					<div className="sm:flex sm:items-center mt-8">
						<div className="sm:flex-auto">
							<h2 className="text-2xl font-semibold text-black">Members</h2>
						</div>
						<div className="sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to="/invitations/new"
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
								onClick={handleUserAdd}
							>
								Add New
							</Link>
						</div>
					</div>

					{users?.length > 0 ? (
						<div className="">
							<div className="mt-8 flow-root">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<table className="min-w-full divide-y divide-gray-300">
											<thead className="bg-white">
												<tr>
													<th
														scope="col"
														className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
													>
														First Name
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Last Name
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Email
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Phone
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Admin
													</th>
													<th
														scope="col"
														className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														View
													</th>
												</tr>
											</thead>
											<tbody className="divide-y divide-gray-200 bg-white">
												{users?.map((user, userIdx) => (
													<tr
														key={user.id}
														className={userIdx % 2 === 0 ? undefined : 'bg-white'}
													>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
															{user.firstName}
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0  truncate">
															{user.lastName}
														</td>
														<td
															onClick={() => {
																if (revealedEmails.includes(user.id)) {
																	setRevealedEmails(
																		revealedEmails.filter((id) => id !== user.id),
																	);
																} else {
																	setRevealedEmails([...revealedEmails, user.id]);
																}
															}}
															className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate cursor-pointer ${
																revealedEmails.includes(user.id) ? '' : 'blur-sm'
															}`}
														>
															{user.email}
														</td>
														<td
															onClick={() => {
																if (revealedPhones.includes(user.id)) {
																	setRevealedPhones(
																		revealedPhones.filter((id) => id !== user.id),
																	);
																} else {
																	setRevealedPhones([...revealedPhones, user.id]);
																}
															}}
															className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 cursor-pointer ${
																revealedPhones.includes(user.id) ? '' : 'blur-sm'
															}`}
														>
															{user.phone}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															<select
																id="isLeader"
																name="isLeader"
																defaultValue={user.isLeader ? 'Admin' : 'Member'}
																className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
																onChange={(event) => {
																	event.preventDefault();
																	const newRole = event.target.value;
																	const newIsLeader = newRole === 'Admin';
																	if (newIsLeader !== user.isLeader) {
																		setUserToChangeRole({
																			...user,
																			isLeader: newIsLeader,
																		});
																		setAdminConfirm(true);
																	}
																}}
															>
																<option>Admin</option>
																<option>Member</option>
															</select>
														</td>
														<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
															<Link
																to={`/users/${user.id}`}
																className="font-medium text-neutral-800 hover:text-neutral-900"
															>
																View
															</Link>
														</td>
														<td className="relative py-4 text-sm font-medium">
															<button
																type="button"
																onClick={() => {
																	removeUser(user.id);
																}}
																className="justify-center py-1 px-2 border border-transparent text-sm font-medium rounded-md text-red-800 bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
															>
																Remove
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="text-center mt-8 bg-white rounded-lg px-4 py-16">
							<div className="mx-auto max-w-lg">
								<h3 className="mt-2 text-lg font-semibold text-gray-900">No members</h3>
								<p className="mt-1 text-sm text-gray-500">
									Get started by adding members to your space.
								</p>
								<div className="mt-6">
									<Link
										to="/invitations/new"
										onClick={handleUserAdd}
										className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
									>
										Add New
									</Link>
								</div>
							</div>
						</div>
					)}
				</div>

				{usersTotal !== 0 && (
					<Pagination
						page={usersPage}
						setPage={setUsersPage}
						numPages={usersNumPages}
						startIndex={usersStartIndex}
						endIndex={usersEndIndex}
						total={usersTotal}
					/>
				)}
			</>
		);
	};

	const renderInvitationsTab = () => {
		return (
			<>
				<div className={groupId ? '' : 'hidden'}>
					<div className="mt-9">
						<div className="sm:flex sm:items-center">
							<div className="sm:flex-auto">
								<h1 className="text-2xl font-semibold text-gray-900">Pending Invitations</h1>
							</div>
						</div>
					</div>

					{invitations?.length > 0 ? (
						<div className="">
							<div className="mt-8 flow-root">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<table className="min-w-full divide-y divide-gray-300">
											<thead className="bg-white">
												<tr>
													<th
														scope="col"
														className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
													>
														First Name
													</th>
													<th
														scope="col"
														className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
													>
														Last Name
													</th>
													<th
														scope="col"
														className="x-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Email
													</th>
													<th
														scope="col"
														className="x-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Phone
													</th>
													<th
														scope="col"
														className="x-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
													>
														Role
													</th>
												</tr>
											</thead>
											<tbody className="ivide-y divide-gray-200 bg-white">
												{invitations?.map((invitation, invitationIdx) => (
													<tr
														key={invitation.id}
														className={invitationIdx % 2 === 0 ? undefined : 'bg-white'}
													>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
															{invitation.firstName}
														</td>
														<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
															{invitation.lastName}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
															{invitation.email}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate">
															{invitation.phone}
														</td>
														<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
															{humanize(invitation.groupRole)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="text-center mt-8 bg-white rounded-lg px-4 py-16">
							<div className="mx-auto max-w-lg">
								<h3 className="mt-2 text-lg font-semibold text-gray-900">No pending invitations</h3>
								<p className="mt-1 text-sm text-gray-500">
									Get started by inviting new members to your space.
								</p>
								<div className="mt-6">
									<Link
										to="/invitations/new"
										onClick={handleUserAdd}
										className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
									>
										Add New
									</Link>
								</div>
							</div>
						</div>
					)}
				</div>

				{invitationsTotal !== 0 && (
					<Pagination
						page={invitationsPage}
						setPage={setInvitationsPage}
						numPages={invitationsNumPages}
						startIndex={invitationsStartIndex}
						endIndex={invitationsEndIndex}
						total={invitationsTotal}
					/>
				)}
			</>
		);
	};

	const renderExtras = () => {
		return (
			<>
				<UserSearch
					isOpen={userSearchOpen}
					groupId={groupId}
					actionLabel="Add"
					onActionClick={addUser}
					onCancel={() => {
						setUserSearchOpen(false);
					}}
					roles="admin,leader,member"
				/>
				<Confirm
					isOpen={deleteConfirm}
					title="Delete Group"
					body="This will delete the space all its activity and cannot be undone. Are you sure?"
					onConfirm={deleteGroup}
					onCancel={() => setDeleteConfirm(false)}
				/>
				<Confirm
					isOpen={adminConfirm}
					title={userToChangeRole?.isLeader ? 'Make Admin' : 'Remove Admin'}
					body={`This will ${
						userToChangeRole?.isLeader ? 'Make this user an admin' : 'Remove this users admin privileges'
					}. Are you sure?`}
					onConfirm={changeUserRole}
					onCancel={() => {
						setAdminConfirm(false);
						setUserToChangeRole(null);
					}}
				/>
			</>
		);
	};

	const renderContent = () => {
		switch (selectedTab) {
			case 'Details':
				return renderDetails();
			case 'Members':
				return renderUsersTab();
			case 'Invitations':
				return renderInvitationsTab();
			case 'Content':
				return renderContentTab();
		}
	};

	if (groupId) {
		const tabs = ['Details', 'Members', 'Invitations', 'Content'];
		return (
			<form className="px-8 py-2" id="group-form" onSubmit={handleSubmit(onSubmit)}>
				<div className="">
					<div className="sm:hidden">
						<label htmlFor="tabs" className="sr-only">
							Select a tab
						</label>
						<select
							id="tabs"
							name="tabs"
							defaultValue={'Details'}
							className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
						>
							{tabs.map((tab) => (
								<option key={`${tab}-option`}>{tab}</option>
							))}
						</select>
					</div>
					<div className="hidden sm:block">
						<div className="border-b border-gray-200">
							<nav aria-label="Tabs" className="-mb-px flex space-x-8">
								{tabs.map((tab) => (
									<a
										key={`${tab}-link`}
										className={classNames(
											tab === selectedTab
												? 'border-blue-500 text-blue-600'
												: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
											'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
										)}
										onClick={() => {
											setSelectedTab(tab);
										}}
									>
										{tab}
									</a>
								))}
							</nav>
						</div>
					</div>
					<div className="">{renderContent()}</div>
					{renderExtras()}
				</div>
			</form>
		);
	}

	return (
		<form className="p-10" id="group-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="mb-6 text-3xl tracking-tight font-semibold text-gray-900 ">
							{groupId ? `${group?.name}` : 'New Space'}
						</h3>
					</div>

					{renderDetails()}
				</div>
			</div>
			{renderExtras()}
		</form>
	);
}

export default observer(GroupForm);
