import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Confirm from '../Confirm';
import { humanize } from '../../utils/stringUtils';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';

function AssessmentForm() {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			audience: 'leaders',
		},
	});

	const { assessmentId } = useParams();
	const [assessment, setAssessment] = useState(null);
	const [timezones, setTimezones] = useState([]);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);

	let navigate = useNavigate();

	const watchStartDate = watch('startDate');
	const watchEndDate = watch('endDate');

	useEffect(() => {
		if (!timezones.length) {
			fetchTimezones();
		}

		if (assessmentId && !assessment && timezones.length && orgsStore.orgs.length) {
			fetchAssessment(assessmentId);
		}

		setSuccessMessage(assessmentId ? 'Assessment updated!' : 'Assessment created!');
	}, [assessment, timezones, orgsStore.currentOrg, deleteConfirm]);

	const fetchAssessment = async (assessmentId) => {
		const assessmentResponse = await tetherApi.getAssessment(assessmentId);

		setAssessment(assessmentResponse.data);

		reset(assessmentResponse.data);
	};

	const fetchTimezones = async () => {
		const timezonesResponse = await tetherApi.getTimezones();

		setTimezones(timezonesResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitAssessment(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/assessments');
		}
	};

	const reduceQuestionIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var question = assessment.assessmentQuestions[parseInt(currentIndex)];

		await tetherApi.updateAssessmentQuestion(assessmentId, question.id, {
			index: question.index - 1,
		});

		fetchAssessment(assessmentId);
	};

	const increaseQuestionIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var question = assessment.assessmentQuestions[parseInt(currentIndex)];

		await tetherApi.updateAssessmentQuestion(assessmentId, question.id, {
			index: question.index + 1,
		});

		fetchAssessment(assessmentId);
	};

	const reduceLinkIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var link = assessment.assessmentActionLinks[parseInt(currentIndex)];

		await tetherApi.updateAssessmentActionLink(assessmentId, link.id, {
			index: link.index - 1,
		});

		fetchAssessment(assessmentId);
	};

	const increaseLinkIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var link = assessment.assessmentActionLinks[parseInt(currentIndex)];

		await tetherApi.updateAssessmentActionLink(assessmentId, link.id, {
			index: link.index + 1,
		});

		fetchAssessment(assessmentId);
	};

	const submitAssessment = async (data) => {
		const formData = new FormData(document.getElementById('assessment-form'));

		if (data.id) {
			return await tetherApi.updateAssessment(data.id, formData);
		} else {
			return await tetherApi.createAssessment(formData);
		}
	};

	const deleteAssessment = async () => {
		const result = await tetherApi.deleteAssessment(assessmentId);

		if (result) {
			alertService.success('Assessment deleted');

			navigate('/assessments');
		}
	};

	return (
		<form className="p-12 space-y-8" id="assessment-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-xl font-semibold  text-gray-900 ">
							{assessmentId ? 'Edit' : 'New'} Survey
						</h3>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Access</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select what user role you want to access the survey.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="audience" className="block text-sm font-medium leading-6 text-gray-900">
									User Role
								</label>
								<div className="mt-2">
									<select
										{...register('audience')}
										disabled={assessment !== null}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="audience-all" value="all">
											All Users
										</option>
										<option key="audience-admins" value="admins">
											Administrator
										</option>
										<option key="audience-leaders" value="leaders">
											Moderator
										</option>
										<option key="audience-members" value="members">
											Member
										</option>
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Audience cannot be changed after the survey is saved
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name, subtitle, and completion message.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('title', { required: 'Title is required' })}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.title?.message ? 'mt-2 text-sm text-red-600 font-regular' : 'hidden'
										}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="subtitle" className="block text-sm font-medium leading-6 text-gray-900">
									Description
								</label>
								<div className="mt-2">
									<input
										{...register('subtitle')}
										type="text"
										placeholder="A short description about the survey"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.subtitle?.message
												? 'mt-2 text-sm text-red-600 font-regular'
												: 'hidden'
										}
										id="subtitle-error"
									>
										{errors.subtitle?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="confirmationMessage"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Completion Message
								</label>
								<div className="mt-2">
									<textarea
										{...register('confirmationMessage')}
										rows={4}
										className=" shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										This message shows up after the user has completed the survey
									</p>
									<p
										className={
											errors.confirmationMessage?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="confirmationMessage-error"
									>
										{errors.confirmationMessage?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Schedule</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the schedule, time, and duration.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label
									htmlFor="frequency"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Frequency*
								</label>
								<div className="mt-2">
									<select
										{...register('frequency', {
											required: 'Frequency is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="frequency-weekly" value="weekly">
											Weekly
										</option>
										<option key="frequency-biweekly" value="biweekly">
											Bi-Weekly
										</option>
										<option key="frequency-monthly" value="monthly">
											Monthly
										</option>
										<option key="frequency-quarterly" value="quarterly">
											Quarterly
										</option>
									</select>
									<p
										className={
											errors.frequency?.message
												? 'mt-2 text-sm text-red-600 font-regular'
												: 'hidden'
										}
										id="frequency-error"
									>
										{errors.frequency?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="startDate"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Start Date*
								</label>
								<div className="mt-2">
									<ReactDatePicker
										{...register('startDate')}
										disabled={!!assessmentId}
										dateFormat={'MMMM d, yyyy'}
										selected={watchStartDate ? moment(watchStartDate).toDate() : null}
										onChange={(date) => setValue('startDate', date)}
										minDate={
											assessment?.minAllowedStartDate
												? moment(assessment?.minAllowedStartDate).toDate()
												: null
										}
										maxDate={watchEndDate}
										calendarClassName="datepicker-calendar"
										type="text"
										autocomplete="off"
										className={`max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular ${
											assessmentId ? 'opacity-30' : ''
										}`}
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										{assessmentId
											? 'You cannot change the start date after creating an survey'
											: 'What date would you like the survey to start?'}
									</p>
								</div>
							</div>

							<div className="col-span-full">
								<label htmlFor="endDate" className="block text-sm font-medium leading-6 text-gray-900">
									End Date
								</label>
								<div className="mt-2">
									<ReactDatePicker
										{...register('endDate')}
										dateFormat={'MMMM d, yyyy'}
										selected={watchEndDate ? moment(watchEndDate).toDate() : null}
										onChange={(date) => setValue('endDate', date)}
										minDate={watchStartDate}
										calendarClassName="datepicker-calendar"
										type="text"
										autocomplete="off"
										className={`max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular ${
											assessmentId ? 'opacity-30' : ''
										}`}
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										{assessmentId
											? 'You cannot change the end date after creating an survey'
											: 'What date would you like the survey to end?'}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="frequencyTimezone"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Timezone*
								</label>
								<div className="mt-2">
									<select
										{...register('frequencyTimezone', {
											required: 'Timezone is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="frequencyTimezone-blank" value="">
											Select a Timezone...
										</option>
										{timezones.map((timezone) => (
											<option key={`timezone-${timezone}`} value={timezone}>
												{timezone}
											</option>
										))}
									</select>
									<p
										className={
											errors.frequencyTimezone?.message
												? 'mt-2 text-sm text-red-600 font-regular'
												: 'hidden'
										}
										id="frequencyTimezone-error"
									>
										{errors.frequencyTimezone?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="frequencyAt"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Time*
								</label>
								<div className="mt-2">
									<input
										{...register('frequencyAt', {
											required: 'Time is required',
										})}
										type="text"
										placeholder="E.g. 14:30"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">Use military time</p>
									<p
										className={errors.frequencyAt?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="frequencyAt-error"
									>
										{errors.frequencyAt?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5"></div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							assessmentId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/assessments"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<div className={assessmentId ? '' : 'hidden'}>
				<div className="mt-16 space-y-8 divide-y divide-gray-200  sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl font-semibold  text-gray-900 ">Survey Questions</h1>
						</div>
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to={`/assessments/${assessment?.id}/assessment_questions/new`}
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							>
								New Question
							</Link>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Question
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Template
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{assessment?.assessmentQuestions?.map((assessmentQuestion, i) => (
											<tr
												key={assessmentQuestion.id}
												className={i % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceQuestionIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === assessment.assessmentQuestions.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === assessment.assessmentQuestions.length - 1
																? null
																: increaseQuestionIndex
														}
													/>
													{assessmentQuestion.question}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{assessmentQuestion.template
														? humanize(assessmentQuestion.template)
														: ''}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-regular sm:pr-6">
													<Link
														to={`/assessments/${assessmentQuestion.assessmentId}/assessment_questions/${assessmentQuestion.id}`}
														className="text-neutral-800 hover:text-neutral-900"
													>
														{assessmentQuestion.template ? 'View' : 'Edit'}
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-16 space-y-8 divide-y divide-gray-200  sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl font-semibold text-gray-900 ">Confirmation Links</h1>
						</div>
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to={`/assessments/${assessment?.id}/assessment_action_links/new`}
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							>
								New Link
							</Link>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Label
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Value
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{assessment?.assessmentActionLinks?.map((assessmentActionLink, i) => (
											<tr
												key={assessmentActionLink.id}
												className={i % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 max-w-md truncate">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceLinkIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === assessment.assessmentActionLinks.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === assessment.assessmentActionLinks.length - 1
																? null
																: increaseLinkIndex
														}
													/>
													{assessmentActionLink.label}
												</td>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 max-w-sm truncate">
													{assessmentActionLink.link}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<Link
														to={`/assessments/${assessmentActionLink.assessmentId}/assessment_action_links/${assessmentActionLink.id}`}
														className="text-neutral-800 hover:text-neutral-900"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Assessment"
				body="This will delete all data for this Assessment and cannot be undone. Are you sure?"
				onConfirm={deleteAssessment}
				onCancel={() => setDeleteConfirm(false)}
			/>
		</form>
	);
}

export default observer(AssessmentForm);
