import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';

function ChallengeList() {
	const [challenges, setChallenges] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchChallenges();
		}
	}, [orgsStore.currentOrg]);

	const fetchChallenges = async () => {
		const challengesResponse = await tetherApi.getChallenges();
		setChallenges(challengesResponse.data);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-3xl font-semibold tracking-tight text-black ">Challenges</h1>
					{/* <h3 className="font-regular text-gray-500">Friendly competitions with others</h3> */}
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://intercom.help/withtether/en/articles/8993292-challenges-on-tether"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
					>
						About Challenges
					</Link>
					<Link
						to="/challenges/new"
						className="inline-flex items-center justify-center rounded-lg border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
					>
						New Challenge
					</Link>
				</div>
			</div>
			<div className="mt-8 flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden md:rounded-lg">
							<table className="min-w-full">
								<thead className="bg-gray-50 ">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-regular text-gray-900  sm:pl-6"
										>
											Name
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="bg-white ">
									{challenges.map((challenge, i) => (
										<tr key={challenge.id} className={i % 2 === 0 ? undefined : 'bg-gray-50 '}>
											<td className="py-4 pl-4 pr-3 text-sm text-gray-900  sm:pl-6 max-w-xs truncate font-regular">
												{challenge.title}
											</td>
											<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												<Link
													to={`/challenges/${challenge.id}`}
													className="text-neutral-800 hover:text-neutral-900"
												>
													Edit
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(ChallengeList);
