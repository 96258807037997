import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { statusPill } from '../../utils/pillHelper';
import { statusLabels } from '../../utils/humanReadableLabels';

function CollectionForm() {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			accessRole: null,
		},
	});

	const { collectionId } = useParams();
	const [collection, setCollection] = useState(null);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [deleteItemConfirm, setDeleteItemConfirm] = useState(false);
	const [deleteCollectionItemId, setDeleteCollectionItemId] = useState(null);

	let navigate = useNavigate();

	useEffect(() => {
		if (collectionId && !collection && orgsStore.orgs.length) {
			fetchCollection(collectionId);
		}

		setSuccessMessage(collectionId ? 'Collection updated!' : 'Collection created!');
	}, [collection, orgsStore.currentOrg, deleteConfirm, deleteItemConfirm]);
	// let tier = orgsStore.getOrgTier();

	const fetchCollection = async (collectionId) => {
		const collectionResponse = await tetherApi.getCollection(collectionId);

		setCollection(collectionResponse.data);

		reset(collectionResponse.data);
	};

	const onSubmit = async (data) => {
		const result = await submitCollection(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/collections');
		}
	};

	const itemTypeName = (type) => {
		if (type == 'Pathway') {
			return 'Plan';
		} else if (type == 'LeaderResource') {
			return 'Resource';
		} else if (type == 'Practice') {
			return 'Practice';
		}
	};

	const submitCollection = async (data) => {
		const formData = new FormData(document.getElementById('collection-form'));

		formData.append('isFeatured', data.isFeatured);
		formData.append('isMembersOnly', data.isMembersOnly);

		if (data.id) {
			return await tetherApi.updateCollection(data.id, formData);
		} else {
			return await tetherApi.createCollection(formData);
		}
	};

	const deleteCollection = async () => {
		const result = await tetherApi.deleteCollection(collectionId);

		if (result) {
			alertService.success('Collection deleted');

			navigate('/collections');
		}
	};

	const reduceCollectionItemIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var collectionItem = collection.collectionItems[parseInt(currentIndex)];

		await tetherApi.updateCollectionItem(collectionId, collectionItem.id, {
			index: collectionItem.index - 1,
		});

		fetchCollection(collectionId);
	};

	const increaseCollectionItemIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var collectionItem = collection.collectionItems[parseInt(currentIndex)];

		await tetherApi.updateCollectionItem(collectionId, collectionItem.id, {
			index: collectionItem.index + 1,
		});

		fetchCollection(collectionId);
	};

	const deleteCollectionItem = async () => {
		if (deleteCollectionItemId) {
			const result = await tetherApi.deleteCollectionItem(collectionId, deleteCollectionItemId);

			if (result) {
				alertService.success('Item removed!');
			}

			setDeleteCollectionItemId(null);
			setDeleteItemConfirm(false);

			fetchCollection(collectionId);
		}
	};

	return (
		<form className="p-12 space-y-8" id="collection-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-xl leading-6 font-semibold  text-gray-900 ">
							{collectionId ? '' : 'New Collection'}
						</h3>
					</div>
					<div className="flex flex-row justify-between items-center w-full">
						<div>
							<span
								className={
									'inline-flex items-center rounded-md px-2.5 py-0.5 mb-2 text-sm font-medium ' +
									statusPill(collection?.status)
								}
							>
								{statusLabels[collection?.status] || ''}
							</span>
							<h3 className="text-3xl font-semibold text-gray-900 max-w-2xl truncate">
								{collection?.title}
							</h3>
						</div>
						<div>
							<div>
								{!!collection?.webUrl && (
									<div>
										<button
											type="button"
											onClick={() => navigator.clipboard.writeText(collection?.webUrl)}
											className="cursor-pointer disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
										>
											Copy URL
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Access</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select whether to publish on the Explore Screen or keep the collection in draft.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
									Status*
								</label>
								<div className="mt-2">
									<select
										{...register('status', { required: 'Status is required' })}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="status-draft" value="draft">
											Draft
										</option>
										<option key="status-published" value="published">
											Published
										</option>
										<option key="status-published-private" value="published-private">
											Private
										</option>
									</select>
									<p
										className={
											errors.status?.message ? 'mt-2 text-sm font-regular text-red-600' : 'hidden'
										}
										id="status-error"
									>
										{errors.status?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="accessRole"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									User Role
								</label>
								<div className="mt-2">
									<select
										{...register('accessRole')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular"
									>
										<option key="access-role-all" value="">
											All Members
										</option>
										<option key="access-role-org-owner" value="org_owner">
											Administrators
										</option>
										<option key="access-role-group-leader" value="group_leader">
											Moderators
										</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name and select whether to feature on the Explore Screen.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('title', { required: 'Title is required' })}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.title?.message ? 'mt-2 text-sm font-regular text-red-600' : 'hidden'
										}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isFeatured')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Featured
										</label>
										<p className="text-gray-500">Feature this collection on Explore Screen.</p>
									</div>
								</div>
								<div className="col-span-full">
									<div className="relative flex gap-x-3 mt-4">
										<div className="flex h-6 items-center">
											<input
												{...register('isMembersOnly')}
												type="checkbox"
												className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
											/>
										</div>
										<div className="text-sm leading-6">
											<label htmlFor="candidates" className="font-medium text-gray-900">
												Members Only
											</label>
											<p className="text-gray-500">Restrict to users with accounts</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							collectionId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						type="button"
						to="/collections"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					<button
						type="submit"
						disabled={!isDirty || isSubmitting}
						className="disabled:opacity-2 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						Save
					</button>
				</div>
			</div>

			<div className={collectionId ? '' : 'hidden'}>
				<div className="mt-16 space-y-8 divide-y divide-gray-200  sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl font-semibold  text-gray-900 ">Items</h1>
						</div>
						<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to={`/collections/${collection?.id}/collection_items/new`}
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							>
								Add Resource
							</Link>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Order
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Type
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Author
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{collection?.collectionItems?.map((collectionItem, i) => (
											<tr key={collectionItem.id} className={i % 2 === 0 ? undefined : ''}>
												<td className="py-4 pr-3 text-sm text-gray-900 max-w-xs truncate font-regular">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceCollectionItemIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === collection?.collectionItems?.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === collection?.collectionItems?.length - 1
																? null
																: increaseCollectionItemIndex
														}
													/>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{itemTypeName(collectionItem.itemType)}
												</td>
												<td className="whitespace-nowrap px-3 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate">
													{collectionItem.item.title || collectionItem.item.name}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{collectionItem.item?.author?.name}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 whitespace-nowrap">
													<button
														type="button"
														onClick={() => {
															setDeleteItemConfirm(true);
															setDeleteCollectionItemId(collectionItem.id);
														}}
														className="mr-3 justify-center py-1 px-2 border border-transparent text-sm font-medium rounded-md text-red-800 bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
													>
														Remove
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Collection"
				body="This will delete this Collection and cannot be undone. Are you sure?"
				onConfirm={deleteCollection}
				onCancel={() => setDeleteConfirm(false)}
			/>
			<Confirm
				isOpen={deleteItemConfirm}
				title="Remove Item"
				body="This will remove the item from the collection. Are you sure?"
				onConfirm={deleteCollectionItem}
				onCancel={() => setDeleteItemConfirm(false)}
			/>
		</form>
	);
}

export default observer(CollectionForm);
