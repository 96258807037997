import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import orgsStore from '../../stores/OrgsStore';
import { observer } from 'mobx-react-lite';
import tetherApi from '../../api/tether';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { Metric, Text, Grid, Card, Col } from '@tremor/react';
import Pagination from '../Pagination';
import { Link } from 'react-router-dom';

function PathwayStats() {
	const { pathwayId } = useParams();
	const [pathway, setPathway] = useState(null);
	const [pathwayViews, setPathwayViews] = useState(0);
	const [pathwaySubscriptions, setPathwaySubscriptions] = useState(0);
	const [pathwayVisits, setPathwayVisits] = useState(0);
	const [pathwayCompletions, setPathwayCompletions] = useState(0);
	const [responses, setResponses] = useState([]);
	const [responsePage, setResponsePage] = useState(1);
	const [totalResponses, setTotalResponses] = useState(0);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [userPathwayStats, setUserPathwayStats] = useState([]);
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);

	useEffect(() => {
		const fetchData = async () => {
			if (pathwayId) {
				const pw = await fetchPathway(pathwayId);
				await fetchPathwayViews(pathwayId);
				await fetchTotalSubscriptions(pathwayId);
				await fetchTotalCompletions(pw);
				await fetchPathwayVisits(pathwayId);
				await fetchUserPathwayStats(pathwayId, page, perPage);
			}
		};
		fetchData();
	}, [pathwayId, page]);

	useEffect(() => {
		fetchResponses(pathwayId);
	}, [responsePage, pathwayId]);

	const fetchUserPathwayStats = async (pathwayId, page, perPage) => {
		try {
			const userPathwayStatsResponse = await tetherApi.getUserPathwayStats(pathwayId, page, perPage);

			if (userPathwayStatsResponse && userPathwayStatsResponse.data) {
				setUserPathwayStats(userPathwayStatsResponse.data);
				const totalResults = userPathwayStatsResponse.total;
				setTotal(totalResults);
				setNumPages(Math.ceil(totalResults / perPage));
				setStartIndex((page - 1) * perPage + 1);
				setEndIndex(Math.min(page * perPage, totalResults));
			} else {
				console.warn('User pathway stats response is undefined or missing data');
				setUserPathwayStats([]);
				setTotal(0);
				setNumPages(1);
				setStartIndex(0);
				setEndIndex(0);
			}
		} catch (error) {
			console.error('Error fetching user pathway stats:', error);
		}
	};

	const fetchPathway = async (pathwayId) => {
		const pathwayResponse = await tetherApi.getPathway(pathwayId);
		setPathway(pathwayResponse.data);
		return pathwayResponse.data;
	};

	const fetchPathwayViews = async (pathwayId) => {
		const viewsResponse = await tetherApi.getPathwayViewsCount(pathwayId);
		setPathwayViews(viewsResponse ?? 0);
	};

	const fetchPathwayVisits = async (pathwayId) => {
		const viewsResponse = await tetherApi.getAhoyMetricsCount({
			org_id: orgsStore.currentOrg.id,
			pathway_id: pathwayId,
		});
		setPathwayVisits(viewsResponse ?? 0);
	};

	const fetchTotalSubscriptions = async (pathwayId) => {
		const subscriptionsResponse = await tetherApi.getTotalPathwaySubscriptions(pathwayId);
		setPathwaySubscriptions(subscriptionsResponse ?? 0);
	};

	const fetchTotalCompletions = async (pw) => {
		const completionsResponse = await tetherApi.getTotalPathwayCompletions(
			pw.id,
			moment(pw.createdAt).format('YYYY-MM-DD'),
		);
		setPathwayCompletions(completionsResponse ?? 0);
	};

	const fetchResponses = async (pathwayId) => {
		const anonResponses = await tetherApi.getPathwayAnonymousResponses(pathwayId, undefined, responsePage, 10);
		setResponses(anonResponses.data);
		setTotalResponses(anonResponses.total);
		setStartIndex(anonResponses.total > 0 ? 10 * (responsePage - 1) + 1 : 0);
		setEndIndex(10 * (responsePage - 1) + anonResponses.data.length);
	};

	if (!pathway) {
		return <div></div>;
	}

	return (
		<div className="px-8">
			<div className="flex flex-row pt-8">
				{pathway?.imageUrl ? (
					<img
						src={pathway.secondaryImageUrl ?? pathway.imageUrl}
						className="w-24 h-24 rounded-lg object-cover"
					/>
				) : (
					<div className="w-24 h-24 rounded-lg bg-gray-300"></div>
				)}
				<div className="pl-8 pt-4">
					<p className="text-2xl pb-.5 font-semibold text-gray-900">{pathway.name}</p>
					<p className="text-base font-regular text-gray-500 ">{`Published ${moment(pathway.createdAt).format(
						'MMMM Do, YYYY',
					)} at ${moment(pathway.createdAt).format('h:mma')}`}</p>
				</div>
			</div>
			<Grid numItemsLg={8} className="gap-6 mt-6">
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Visits</Text>
								<Metric className="font-medium">{pathwayVisits}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Starts</Text>
								<Metric className="font-medium">{pathwaySubscriptions}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Sections Started</Text>
								<Metric className="font-medium">{pathwayViews}</Metric>
							</div>
						</div>
					</Card>
				</Col>
				<Col numColSpanLg={2}>
					<Card>
						<div className="max-w-sm flex flex-row">
							<div>
								<Text className="font-regular">Total Completions</Text>
								<Metric className="font-medium">{pathwayCompletions}</Metric>
							</div>
						</div>
					</Card>
				</Col>
			</Grid>
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto">
						<div className="inline-block min-w-full py-2 align-middle">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
										>
											Name
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Progress
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{userPathwayStats && userPathwayStats.length > 0 ? (
										userPathwayStats.map((userWithStats, index) => {
											const {
												user,
												completedUserPathwaySectionsCount,
												totalPathwaySectionsCount,
											} = userWithStats;
											const progressPercentage =
												totalPathwaySectionsCount > 0
													? (completedUserPathwaySectionsCount / totalPathwaySectionsCount) *
													  100
													: 0;
											return (
												<tr key={`${user.email}-${index}`}>
													<td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
														<div className="flex items-center">
															{/* <div className="h-11 w-11 flex-shrink-0">
																<img
																	alt=""
																	src={user.image}
																	className="h-11 w-11 rounded-full"
																/>
															</div> */}
															<div className="">
																<div className="font-medium text-gray-900">{`${user.firstName} ${user.lastName}`}</div>
																<div className="mt-1 text-gray-500">{user.email}</div>
															</div>
														</div>
													</td>
													<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 min-w-72">
														<div className="font-medium text-gray-900">
															{completedUserPathwaySectionsCount ?? 0} /{' '}
															{totalPathwaySectionsCount ?? 0} Sections Completed
														</div>
														<div aria-hidden="true" className="mt-3">
															<div className="overflow-hidden rounded-full bg-gray-200">
																<div
																	style={{
																		width: `${progressPercentage}%`,
																	}}
																	className="h-2 rounded-full bg-blue-600"
																/>
															</div>
														</div>
													</td>
													<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
														{user.role}
													</td>
													<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
														<Link
															to={`/users/${user.id}`}
															className="font-medium text-neutral-800 hover:text-neutral-900"
														>
															View
														</Link>
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td colSpan="4" className="text-center py-5">
												No data available
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
			<Grid numItemsLg={6} className="gap-6 mt-6">
				<Col numColSpanLg={6}>
					<Card>
						<Text className="font-regular mb-4">Anonymous Feedback</Text>
						{responses.map((resp) => (
							<Text className="mb-2 font-regular text-black" key={resp.id}>{`"${resp.mood.body}"`}</Text>
						))}
						<Pagination
							page={responsePage}
							setPage={setResponsePage}
							numPages={Math.ceil(totalResponses / 10)}
							startIndex={startIndex}
							endIndex={endIndex}
							total={totalResponses}
						/>
					</Card>
				</Col>
			</Grid>
		</div>
	);
}

export default observer(PathwayStats);
