import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import tetherApi from '../../api/tether';
import Pagination from '../Pagination';

function PodcastEpisodesList() {
	const { podcastId } = useParams();
	const [podcastEpisodes, setEpisodes] = useState([]);
	const [page, setPage] = useState(1);
	const [perPage] = useState(10);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchEpisodes();
	}, [page]);

	const fetchEpisodes = async () => {
		try {
			const response = await tetherApi.getPodcastEpisodes(podcastId, page, perPage);
			if (response && response.data) {
				setEpisodes(response.data);
				const totalCount = response.total;
				const pagesCount = Math.ceil(totalCount / perPage);

				setTotal(totalCount);
				setNumPages(pagesCount);
				setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
				setEndIndex(perPage * (page - 1) + response.data.length);
			} else {
				throw new Error('Invalid response format');
			}
		} catch (err) {
			console.error('Failed to fetch episodes:', err);
			setError('Failed to load episodes. Please try again later.');
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-semibold text-black">Episodes</h1>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to={`/podcasts/${podcastId}/episodes/new`}
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
					>
						New Episode
					</Link>
				</div>
			</div>
			{error ? (
				<div className="mt-4 text-red-600">{error}</div>
			) : (
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{podcastEpisodes.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No episodes to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No episodes are available.
										</p>
										<Link
											to="/plans/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											Add Episode
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Title
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Description
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{podcastEpisodes.map((episode) => (
											<tr key={episode.id}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{episode.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{episode.description}
												</td>
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/podcasts/${podcastId}/episodes/${episode.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			)}
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default PodcastEpisodesList;
