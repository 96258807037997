import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { humanize } from '../../utils/stringUtils';
import { statusPill } from '../../utils/pillHelper';
import Pagination from '../Pagination';
import moment from 'moment';
import { statusLabels } from '../../utils/humanReadableLabels';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

// import IntroductionCard from '../IntroductionCard';

function OrgHabitsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [orgHabits, setOrgHabits] = useState([]);
	const [sortBy, setSortBy] = useState('title');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchOrgHabits();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchOrgHabits();
	}, [sortBy, sortOrder]);

	const fetchOrgHabits = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const orgHabitsResponse = await tetherApi.getOrgHabits(page, perPage, opts);

		setOrgHabits(orgHabitsResponse.data);

		const totalCount = orgHabitsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + orgHabitsResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Habits</h1>
					<p className="text-sm text-gray-500">
						Scheduled reminders designed to help your users integrate your content
					</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://intercom.help/withtether/en/articles/9535043-about-habits"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link>
					<Link
						to="/habits/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800 lg:mt-0"
					>
						New Habit
					</Link>
				</div>
			</div>
			{/* <IntroductionCard
				localStorageKey="hideHabitsVideo"
				vimeoLink="https://player.vimeo.com/video/858737515?h=9bc854d785"
				bullets={[
					// eslint-disable-next-line quotes
					"Common use cases include praying for a friend or spouse, reading the Bible, taking a sabbath or a specific repeatable rhythm key to your organization's vision.",
					'Add end dates on a Habit to create a seasonal suggestion.',
					'Encourage Habits that don\'t seem "spiritual" — exercise, call your parents, etc. — in an effort to teach out the idea that everything we do should be with and for God.',
				]}
				type="Habits"
			/> */}
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{orgHabits.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No habits to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No habits are available.
										</p>
										<Link
											to="/habits/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Habit
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'title') {
														setSortBy('title');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Name
													{sortBy === 'title' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'status') {
														setSortBy('status');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Status
													{sortBy === 'status' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'frequency') {
														setSortBy('frequency');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Frequency
													{sortBy === 'frequency' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Start Date
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												End Date
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'is_featured') {
														setSortBy('is_featured');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Featured
													{sortBy === 'is_featured' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'is_members_only') {
														setSortBy('is_members_only');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Members Only
													{sortBy === 'is_members_only' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'updated_at') {
														setSortBy('updated_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last Updated
													{sortBy === 'updated_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											{/* <th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-regular text-gray-900"
										>
											Stats
										</th> */}
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{orgHabits.map((orgHabit, orgHabitIdx) => (
											<tr
												key={orgHabit.id}
												className={orgHabitIdx % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate sm:pl-0">
													{orgHabit.title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<span
														className={
															'inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium ' +
															statusPill(orgHabit.status)
														}
													>
														{statusLabels[orgHabit.status] || 'No Status'}
													</span>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{humanize(orgHabit.frequency)}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{orgHabit.startDate ? moment(orgHabit.startDate).format('LL') : ''}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{orgHabit.endDate ? moment(orgHabit.endDate).format('LL') : ''}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{orgHabit.isFeatured ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm max-w-xs truncate text-gray-500">
													{orgHabit.isMembersOnly ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(orgHabit.updatedAt).format('LL')}
												</td>
												{/* {(!orgsStore?.currentOrg || orgsStore.currentOrg?.isPublisher) && (
												<td className="relative py-4 pl-3 text-sm font-medium">
													<Link
														to={`/habits/${orgHabit.id}/stats`}
														className="text-neutral-800 hover:text-neutral-900"
													>
														Stats<span className="sr-only">, {orgHabit.name}</span>
													</Link>
												</td>
											)} */}
												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/habits/${orgHabit.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit<span className="sr-only">, {orgHabit.name}</span>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>

			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(OrgHabitsList);
