import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Confirm from '../Confirm';
import { alertService } from '../../services/alert.service';
import { humanize } from '../../utils/stringUtils';
import Pagination from '../Pagination';
import SearchBox from '../SearchBox';
import { formatPhoneNumber } from '../../utils/stringUtils';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

// import { XMarkIcon } from '@heroicons/react/20/solid';

function InvitationsList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [invitations, setInvitations] = useState([]);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [deleteInvitationId, setDeleteInvitationId] = useState(null);
	const [exportConfirm, setExportConfirm] = useState(false);
	const [sortBy, setSortBy] = useState('first_name');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchInvitations();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchInvitations();
	}, [sortBy, sortOrder]);

	let navigate = useNavigate();

	const tz = moment.tz.guess();

	const fetchInvitations = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const invitationsResponse = await tetherApi.getInvitations(page, perPage, opts);

		setInvitations(invitationsResponse.data);

		const totalCount = invitationsResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + invitationsResponse.data.length);
	};

	const createExport = async () => {
		const result = await tetherApi.createExport({ exportType: 'Invitation' });

		if (result) {
			alertService.success('Export is being processed!');

			navigate('/exports');
		}
	};

	const searchInvitations = async (query) => {
		if (query) {
			const invitationsResponse = await tetherApi.searchInvitations(query);
			if (invitationsResponse?.data) {
				setInvitations(invitationsResponse.data);
			}
		} else {
			fetchInvitations();
		}
	};

	const deleteInvitation = async () => {
		if (deleteInvitationId) {
			const result = await tetherApi.deleteInvitation(deleteInvitationId);

			if (result) {
				alertService.success('User removed!');
			}

			setDeleteInvitationId(null);
			setDeleteConfirm(false);

			fetchInvitations();
		}
	};

	const resend = async (invitationId) => {
		if (invitationId) {
			const result = await tetherApi.resendInvitation(invitationId);

			if (result) {
				alertService.success('Invitation resent!');
			}

			fetchInvitations();
		}
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="pointer-events-none inset-x-0 top-0 sm:pb-5">
				<div className="pointer-events-auto flex items-center justify-between bg-gray-100 xs:rounded-md xs:px-4 xs:mt-4 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
					<p className="text-sm leading-6 text-neutral-800">
						<a href="mailto:support@withtether.com">
							<strong className="font-semibold">Bulk Invitations</strong>
							<svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
								<circle cx={1} cy={1} r={1} />
							</svg>
							Are you looking to bulk invite users? Contact us to request access and learn more&nbsp;
							<span aria-hidden="true">&rarr;</span>
						</a>
					</p>
				</div>
			</div>
			<div className="sm:flex sm:items-center justify-between">
				<div className="mr-4">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Invitations</h1>
				</div>
				<SearchBox
					onQueryChange={searchInvitations}
					onSearchCancel={fetchInvitations}
					items={invitations}
					itemText="Invitations"
				/>

				<div className="sm:mt-0 xs:ml-0 ml-4 sm:flex-none">
					<button
						type="button"
						onClick={() => {
							setExportConfirm(true);
						}}
						className={
							total > 0
								? 'mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm'
								: 'hidden'
						}
					>
						Export CSV
					</button>
					<Link
						to="/invitations/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900 xs:mt-4 lg:mt-0"
					>
						New Invitation
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{invitations.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No invitations to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No invitations are available.
										</p>
										<Link
											to="/invitations/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Invitation
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'first_name') {
														setSortBy('first_name');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													First
													{sortBy === 'first_name' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'last_name') {
														setSortBy('last_name');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last
													{sortBy === 'last_name' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'email') {
														setSortBy('email');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Email
													{sortBy === 'email' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'phone') {
														setSortBy('phone');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Phone
													{sortBy === 'phone' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'created_at') {
														setSortBy('created_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Sent
													{sortBy === 'created_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											{/* <th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
										>
											Expires
										</th> */}
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Accepted
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'role') {
														setSortBy('role');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Role
													{sortBy === 'role' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Groups
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
												<span className="sr-only">Delete</span>
											</th>
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200 bg-white">
										{invitations.map((invitation, invitationIdx) => (
											<tr
												key={invitation.id}
												className={invitationIdx % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
													{invitation.firstName}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
													{invitation.lastName}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{invitation.email}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{formatPhoneNumber(invitation.phone)}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment.tz(invitation.createdAt, tz).format('MMMM Do, YYYY')}
												</td>
												{/* <td className="px-3 py-4 text-sm text-gray-500 max-w-sm truncate font-regular">
												{moment.tz(invitation.expiresAt, tz).format('MMMM Do, YYYY')}
											</td> */}
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{invitation.acceptedAt
														? moment.tz(invitation.acceptedAt, tz).format('MMMM Do, YYYY')
														: ''}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{humanize(invitation.role)}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{invitation.group?.name}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 whitespace-nowrap">
													<button
														type="button"
														onClick={() => {
															setDeleteConfirm(true);
															setDeleteInvitationId(invitation.id);
														}}
														className="mr-3 justify-center py-1 px-2 border border-transparent text-sm font-medium rounded-md text-red-800 bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
													>
														Remove
													</button>

													<button
														type="button"
														onClick={() => {
															resend(invitation.id);
														}}
														className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-sm font-medium text-green-800 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
													>
														Resend
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>

			<Confirm
				isOpen={deleteConfirm}
				title="Remove User"
				body="This will revoke the user's access to your organization. Are you sure?"
				onConfirm={deleteInvitation}
				onCancel={() => setDeleteConfirm(false)}
			/>
			<Confirm
				isOpen={exportConfirm}
				title="Export Invitations"
				body="This will create a .csv file and email it to your email address. You will also be able to view it by clicking Account > Exports. Are you sure you want to export your invitations?"
				onConfirm={createExport}
				onCancel={() => setExportConfirm(false)}
			/>
		</div>
	);
}

export default observer(InvitationsList);
