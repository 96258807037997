import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { humanize } from '../../utils/stringUtils';

function CustomFieldsList() {
	const [customFields, setCustomFields] = useState([]);

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchCustomFields();
		}
	}, [orgsStore.currentOrg]);

	const fetchCustomFields = async () => {
		const customFieldsResponse = await tetherApi.getCustomFields();

		setCustomFields(customFieldsResponse.data);
	};

	const reduceCustomFieldIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var customField = customFields[parseInt(currentIndex)];

		await tetherApi.updateCustomField(customField.id, {
			index: customField.index - 1,
		});

		fetchCustomFields();
	};

	const increaseCustomFieldIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var customField = customFields[parseInt(currentIndex)];

		await tetherApi.updateCustomField(customField.id, {
			index: customField.index + 1,
		});

		fetchCustomFields();
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-lg font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-2 lg:pb-0">Links</h1>
					<p className="text-sm text-gray-500">Organize and manage important links on the Explore screen</p>
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					{/* <Link
						to="https://intercom.help/withtether/en/articles/9731109-about-custom-fields"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link> */}
					<Link
						to="/links/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800 lg:mt-0"
					>
						New Link
					</Link>
				</div>
			</div>
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{customFields.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No links to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No links are available.
										</p>
										<Link
											to="/links/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Link
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="y-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
											>
												Name
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Type
											</th>
											<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
												<span className="sr-only">Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{customFields.map((customField, i) => (
											<tr key={customField.id} className={i % 2 === 0 ? undefined : ''}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate sm:pl-0">
													<ArrowUpCircleIcon
														data-index={i}
														className={
															i === 0
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={i === 0 ? null : reduceCustomFieldIndex}
													/>
													<ArrowDownCircleIcon
														data-index={i}
														className={
															i === customFields.length - 1
																? 'opacity-25 inline-block h-5 w-5 mr-4'
																: 'cursor-pointer inline-block h-5 w-5 mr-4'
														}
														onClick={
															i === customFields.length - 1
																? null
																: increaseCustomFieldIndex
														}
													/>

													{customField.label}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-sm truncate">
													{humanize(customField.fieldType)}
												</td>
												<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
													<Link
														to={`/links/${customField.id}`}
														className="text-neutral-800 hover:text-neutral-900"
													>
														Edit
														<span className="sr-only">, {customField.name}</span>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default observer(CustomFieldsList);
